// LineupItem.js
import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const LineupItem = () => {
  const [lineupData, setLineupData] = useState(null);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const contentRef = useRef(null);
  const containerRef = useRef(null);
  const [animationDuration, setAnimationDuration] = useState(0); // Start with 0 duration
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    axios
      .get('/api/next_week_programs_list/')
      .then((response) => {
        if (response.data.lineup) {
          setLineupData(response.data.lineup);
        } else if (response.data.message) {
          setMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching lineup:', error);
        setError('Failed to load lineup data.');
      });
  }, []);

  useLayoutEffect(() => {
    const calculateAnimationDuration = () => {
      if (contentRef.current && containerRef.current) {
        const contentHeight = contentRef.current.scrollHeight / 2; // Since content is duplicated
        const containerHeight = containerRef.current.clientHeight;

        if (contentHeight > containerHeight) {
          setIsOverflowing(true);
          const duration = (contentHeight / 50) * 10; // Adjust as needed
          setAnimationDuration(duration);
        } else {
          // Content fits within container; no need to scroll
          setIsOverflowing(false);
          setAnimationDuration(0);
        }
      }
    };

    calculateAnimationDuration();

    window.addEventListener('resize', calculateAnimationDuration);
    return () => window.removeEventListener('resize', calculateAnimationDuration);
  }, [lineupData]);

  if (error) {
    return (
      <div className="set-item lineup-item">
        <p>{error}</p>
      </div>
    );
  }

  if (message) {
    return (
      <div className="set-item lineup-item">
        <p>{message}</p>
      </div>
    );
  }

  if (!lineupData) {
    return (
      <div className="set-item lineup-item">
        <p>Loading lineup...</p>
      </div>
    );
  }

  // Function to render lineup content with unique key prefixes
  const renderLineupContent = (keyPrefix = '') => (
    <div className="lineup-content" key={`${keyPrefix}lineup-content`}>
        
      {lineupData.map((entry, index) => (
        <div key={`${keyPrefix}lineup-entry-${index}`} className="lineup-section">
          <h3>Program this week 🔉</h3>
          <h4>{entry.date} | {entry.program}</h4>
          <ul>
            {entry.performances.map((performance, idx) => (
              <li key={`${keyPrefix}lineup-perf-${index}-${idx}`}>{performance}</li>
            ))}
            <li>{entry.location}</li>
          </ul>
          
          {index < lineupData.length - 1 && <hr className="lineup-divider" />}
        </div>
      ))}
    </div>
  );

  return (
    <div className="set-item lineup-item" ref={containerRef}>
      {isOverflowing ? (
        <div
          className="lineup-wrapper"
          style={{
            animation: `scroll-up ${animationDuration}s linear infinite`,
          }}
          ref={contentRef}
        >
          {renderLineupContent('first-')}
          {renderLineupContent('second-')}
        </div>
      ) : (
        <div className="lineup-wrapper" ref={contentRef}>
          {renderLineupContent('static-')}
        </div>
      )}
    </div>
  );
};

export default LineupItem;
