// src/App.js

import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from './Sidebar';
import DjSets from './DjSets';
import MusicPlayer from './MusicPlayer';
import TopMenu from './TopMenu';
import Apply from './Apply';
import UserAdmin from './UserAdmin';
import Booking from './Booking';
import Login from './Login';
import Signup from './Signup';
import OperationAdmin from './OperationAdmin';
import ContactUs from './ContactUs';
import DjProfile from './DjProfile';
import MaterialAdminList from './MaterialAdminList';
import MaterialAdminDetail from './MaterialAdminDetail';
import AccountSettings from './AccountSettings';
import AvailableSlotsAdmin from './AvailableSlotsAdmin';
import ScrollToTop from './ScrollToTop'; 
import './App.css';
import { Helmet } from 'react-helmet';
import { Routes, Route, useLocation } from 'react-router-dom';
import axios from 'axios';
import ProtectedRoute from './ProtectedRoute';
import ContentManage from './ContentManage';
import ContentEventDetail from './ContentEventDetail';

const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [currentTrack, setCurrentTrack] = useState('');
  const [currentSet, setCurrentSet] = useState(null);
  const [djInfo, setDjInfo] = useState(null);

  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setSidebarOpen(window.innerWidth > 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Function to handle DJ set selection
  const handleSetSelection = useCallback((set) => {
    setCurrentTrack(set.audio_file_url);
    setCurrentSet(set);
    setDjInfo(set.dj);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const setSlug = queryParams.get('set');

    if (setSlug) {
      // Fetch the DJ set by slug
      axios
        .get(`/api/performances/slug/${setSlug}/`)
        .then((response) => {
          const set = response.data;
          handleSetSelection(set);
        })
        .catch((error) => {
          console.error('Error fetching DJ set:', error);
        });
    } else {
      // If no set is selected, reset the music player
      setCurrentTrack('');
      setCurrentSet(null);
      setDjInfo(null);
    }
  }, [location.search, handleSetSelection]);

  // Determine if current route is booking
  const isBookingRoute = location.pathname === '/booking';

  return (
    <div className="app">
      <Helmet>
        {/* Your meta tags here */}
      </Helmet>


      {/* ScrollToTop Component */}
      <ScrollToTop />

      <TopMenu toggleSidebar={toggleSidebar} onSetSelect={handleSetSelection} />

      {!isBookingRoute && (
        <div className={`sidebar-container ${sidebarOpen ? 'show' : ''}`}>
          <Sidebar />
        </div>
      )}

      <div className={`main-content ${isBookingRoute ? 'full-width' : ''}`}>
        <Routes>
          <Route path="/" element={<DjSets onSetSelect={handleSetSelection} />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/dj/profile"
            element={
              <ProtectedRoute roles={['dj', 'superuser', 'content_manager', 'operation_admin', 'user_admin']}>
                <DjProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dj/account_settings"
            element={
              <ProtectedRoute>
                <AccountSettings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/booking"
            element={
              <ProtectedRoute roles={['superuser', 'dj', 'content_manager', 'operation_admin', 'user_admin']}>
                <Booking />
              </ProtectedRoute>
            }
          />
          {/* Add other routes here */}
          <Route
            path="/admin/available-slots"
            element={
              <ProtectedRoute roles={['superuser']}>
                <AvailableSlotsAdmin />
              </ProtectedRoute>
            }
          />
          <Route
              path="/content_manager"
              element={
                  <ProtectedRoute roles={['content_manager', 'superuser']}>
                      <ContentManage />
                  </ProtectedRoute>
              }
          />
          <Route
              path="/content_manager/events/:eventId"
              element={
                  <ProtectedRoute roles={['content_manager', 'superuser']}>
                      <ContentEventDetail />
                  </ProtectedRoute>
              }
          />
          <Route
            path="/admin/users"
            element={
              <ProtectedRoute roles={['superuser', 'user_admin']}>
                <UserAdmin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/operations"
            element={
              <ProtectedRoute roles={['operation_admin', 'superuser']}>
                <OperationAdmin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/material_admin"
            element={
              <ProtectedRoute roles={['material_admin', 'superuser']}>
                <MaterialAdminList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/material_admin/:performanceId"
            element={
              <ProtectedRoute roles={['material_admin', 'superuser']}>
                <MaterialAdminDetail />
              </ProtectedRoute>
            }
          />

          <Route path="/contact" element={<ContactUs />} />
        </Routes>
        
      </div>
      <MusicPlayer audioSrc={currentTrack} selectedSet={currentSet} djInfo={djInfo} />
    </div>
  );
};

export default App;
