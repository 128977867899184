import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './OperationAdmin.css';
import './loading-overlay.css';
import { toast } from 'react-toastify';

import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import getCroppedImg from './cropImage';

const TABS = [
  { key: 'events', label: 'Events', endpoint: '/api/events', canCreate: false },
  { key: 'performances', label: 'Performances', endpoint: '/api/performance', canCreate: true },
  { key: 'djs', label: 'DJs', endpoint: '/api/djs', canCreate: true },
  { key: 'labels', label: 'Labels', endpoint: '/api/labels', canCreate: true },
  { key: 'partners', label: 'Partners', endpoint: '/api/partners', canCreate: true },
  { key: 'locations', label: 'Locations', endpoint: '/api/locations', canCreate: true },
];

const OperationAdmin = () => {
  const [activeTab, setActiveTab] = useState('events');
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [originalItem, setOriginalItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingSets, setLoadingSets] = useState(false);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [allEvents, setAllEvents] = useState([]); 
  const [allDjs, setAllDjs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const predefinedTimes = [
    "00:00:00","01:00:00","02:00:00","03:00:00","04:00:00","05:00:00","06:00:00","07:00:00",
    "08:00:00","09:00:00","10:00:00","11:00:00","12:00:00","13:00:00","14:00:00","15:00:00",
    "16:00:00","17:00:00","18:00:00","19:00:00","20:00:00","21:00:00","22:00:00","23:00:00"
  ];
  const mixerModels = [
    { value: 'DJM-900 NXS2', label: 'DJM-900 NXS2' },
    { value: 'XONE 96', label: 'XONE 96' }
  ];

  // Cropping states for DJ profile image
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x:0, y:0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  useEffect(() => {
    fetchItems(activeTab);
    if (activeTab === 'performances') {
      fetchAllEvents();
      fetchAllDjs();
    }
  }, [activeTab]);

  useEffect(() => {
    applyFilter();
  }, [items, searchTerm]);

  const fetchItems = async (tabKey) => {
    const tab = TABS.find(t => t.key === tabKey);
    if (!tab) return;
    setLoading(true);
    setSelectedItem(null);
    setIsCreatingNew(false);
    setIsEditing(false);
    try {
      setLoadingSets(true);
      const response = await axios.get(tab.endpoint + '/', { withCredentials: true });
      let data = response.data;
      if (data && data.results) {
        data = data.results;
      }
      data = data || [];
      setItems(data);
    } catch (error) {
      console.error(`Error fetching ${tabKey}:`, error);
      toast.error(`Failed to fetch ${tabKey}`);
    } finally {
      setLoading(false);
      setLoadingSets(false);
    }
  };

  const fetchAllEvents = async () => {
    try {
      const res = await axios.get('/api/events/', { withCredentials: true });
      let data = res.data;
      if (data && data.results) data = data.results;
      setAllEvents(data || []);
    } catch (err) {
      console.error('Error fetching all events:', err);
    }
  };

  const fetchAllDjs = async () => {
    try {
      const res = await axios.get('/api/djs', { withCredentials: true });
      let data = res.data;
      if (data && data.results) data = data.results;
      setAllDjs(data || []);
    } catch (err) {
      console.error('Error fetching all DJs:', err);
    }
  };

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    setOriginalItem({ ...item });
    setIsCreatingNew(false);
    setIsEditing(false);
  };

  const handleFieldChange = (field, value) => {
    setSelectedItem(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleDjSelectionChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    setSelectedItem(prev => ({
      ...prev,
      dj_ids_write: selectedOptions
    }));
  };

  const handleCreateNew = () => {
    setSelectedItem({});
    setOriginalItem(null);
    setIsCreatingNew(true);
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    if (isCreatingNew) {
      setSelectedItem(null);
      setIsCreatingNew(false);
      setIsEditing(false);
    } else if (originalItem) {
      setSelectedItem(originalItem);
      setIsEditing(false);
    }
  };

  const handleSave = async () => {
    const tab = TABS.find(t => t.key === activeTab);
    if (!tab) return;

    if (!isCreatingNew && !selectedItem?.id) {
      toast.error('No item selected.');
      return;
    }

    const url = isCreatingNew
      ? `${tab.endpoint}/`
      : `${tab.endpoint}/${selectedItem.id}/`;

    try {
      let response;

      if (activeTab === 'performances') {
        if (selectedItem.event_id_write) {
          selectedItem.event = selectedItem.event_id_write;
          delete selectedItem.event_id_write;
        }
      }

      // Special handling for DJs with DjProfileCreateSerializer/DjProfileUpdateSerializer
      if (activeTab === 'djs') {
        // For creating a DJ, DjProfileCreateSerializer requires: name, bio, uploaded_profile_pic
        // For updating a DJ, DjProfileUpdateSerializer requires name, bio at minimum. uploaded_profile_pic is optional.

        // Validate required fields for creation:
        if (isCreatingNew) {
          if (!selectedItem.name || !selectedItem.name.trim()) {
            toast.error('Name is required.');
            return;
          }
          if (!selectedItem.bio || !selectedItem.bio.trim()) {
            toast.error('Bio is required.');
            return;
          }
          if (!selectedItem.profile_pic || !(selectedItem.profile_pic instanceof File)) {
            toast.error('Profile picture is required.');
            return;
          }
        }

        // Prepare FormData
        const formData = new FormData();
        if (selectedItem.name) formData.append('name', selectedItem.name);
        if (selectedItem.bio) formData.append('bio', selectedItem.bio);

        // If creating or if we have a new profile_pic file, append uploaded_profile_pic
        if (isCreatingNew || (selectedItem.profile_pic instanceof File)) {
          formData.append('uploaded_profile_pic', selectedItem.profile_pic);
        }

        // Optional fields
        formData.append('instagram', selectedItem.instagram || '');
        formData.append('red', selectedItem.red || '');
        formData.append('soundcloud', selectedItem.soundcloud || '');
        formData.append('bandcamp', selectedItem.bandcamp || '');
        formData.append('wechat', selectedItem.wechat || '');

        if (isCreatingNew) {
          response = await axios.post(url, formData, { withCredentials: true });
          toast.success('Created successfully.');
        } else {
          // Update existing DJ
          response = await axios.patch(url, formData, { withCredentials: true });
          toast.success('Updated successfully.');
        }
      } else {
        // Normal JSON update for other tabs
        let payload = { ...selectedItem };
        if (isCreatingNew) {
          response = await axios.post(url, payload, { withCredentials: true });
          toast.success('Created successfully.');
        } else {
          response = await axios.patch(url, payload, { withCredentials: true });
          toast.success('Updated successfully.');
        }
      }

      const savedItem = response.data; 
      const savedId = savedItem.id;

      await fetchItems(activeTab);
      if (savedId) {
        const updatedItem = (items || []).find(it => it.id === savedId);
        if (updatedItem) {
          setSelectedItem(updatedItem);
          setOriginalItem({ ...updatedItem });
        }
      }
      setIsCreatingNew(false);
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving item:', error);
      toast.error('Error saving changes.');
    }
  };

  const handleEditMode = () => {
    if (selectedItem && selectedItem.id && activeTab !== 'events') {
      setIsEditing(true);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const applyFilter = () => {
    if (!searchTerm) {
      setFilteredItems(items);
      return;
    }
    const term = searchTerm.toLowerCase();
    const filtered = items.filter(item => {
      const label = renderListItem(item).toLowerCase();
      return label.includes(term);
    });
    setFilteredItems(filtered);
  };

  const currentTab = TABS.find(t => t.key === activeTab);
  const canCreate = currentTab?.canCreate;
  const readOnly = (activeTab === 'events') || (!isCreatingNew && !isEditing);

  const renderInstructionForTab = () => {
    if (activeTab === 'events') {
      return <p className="field-instructions">All fields read-only. You cannot edit events.</p>;
    }

    if (activeTab === 'performances') {
      return (
        <p className="field-instructions">
          <strong>Required:</strong> event (choose when creating), time, mixer_model, is_live.<br />
          When viewing existing performance, event date and name are read-only.<br />
          Optional fields can be left blank. You can also edit DJs.
        </p>
      );
    }

    if (activeTab === 'djs') {
      return (
        <p className="field-instructions">
          <strong>Required (for create):</strong> Name, Bio, Profile Picture<br />
          <strong>Optional:</strong> Instagram, red, soundcloud, wechat
        </p>
      );
    }

    if (activeTab === 'labels') {
      return (
        <p className="field-instructions">
          <strong>Required:</strong> Label Name<br />
          <strong>Optional:</strong> Label Desc, Label Color
        </p>
      );
    }

    if (activeTab === 'partners') {
      return (
        <p className="field-instructions">
          <strong>Required:</strong> Name<br />
          <strong>Optional:</strong> Desc, Color
        </p>
      );
    }

    if (activeTab === 'locations') {
      return (
        <p className="field-instructions">
          <strong>Required:</strong> Location Name<br />
          <strong>Optional:</strong> Address
        </p>
      );
    }

    return null;
  };

  const formatTime12h = (timeStr) => {
    if (!timeStr) return '';
    const [hour, minute] = timeStr.split(':');
    let h = parseInt(hour,10);
    const ampm = h >= 12 ? 'pm' : 'am';
    if (h === 0) h = 12;
    else if (h > 12) h = h - 12;
    return (minute === '00') ? `${h}${ampm}` : `${h}:${minute}${ampm}`;
  };

  const renderListItem = (item) => {
    if (activeTab === 'events') {
      return `${item.date || ''} ${item.event_name || item.id}`;
    } else if (activeTab === 'performances') {
      const djNames = item.dj && item.dj.length > 0
        ? item.dj.map(d => d.name).join(', ')
        : 'No DJ';
      const eventName = item.event_name || 'No event name';
      const timeStr = formatTime12h(item.time);
      return `${item.event_date || ''} ${timeStr} ${djNames} | ${eventName}`;
    } else if (activeTab === 'djs') {
      return `${item.name || item.id}`;
    } else if (activeTab === 'labels') {
      return `${item.label_name || item.id}`;
    } else if (activeTab === 'partners') {
      return `${item.name || item.id}`;
    } else if (activeTab === 'locations') {
      return `${item.location_name || item.id}`;
    }
    return item.id;
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImageBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      const file = new File([croppedImageBlob], 'croppedImage.jpg', { type: 'image/jpeg' });
      setShowCropper(false);

      setSelectedItem(prev => ({
        ...prev,
        profile_pic: file, // This will be uploaded as uploaded_profile_pic
      }));
    } catch (e) {
      console.error(e);
      toast.error('Failed to crop the image.');
    }
  };

  const handleDjProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImageSrc(reader.result);
        setShowCropper(true);
      });
      reader.readAsDataURL(file);
    }
  };

  const renderDjFields = () => {
    const disabledProp = { disabled: readOnly };
  
    return (
      <>
        <label>
          Name (required):
          <input
            type="text"
            value={selectedItem.name || ''}
            onChange={(e) => handleFieldChange('name', e.target.value)}
            {...disabledProp}
          />
        </label>
        <label>
          Bio (required):
          <textarea
            value={selectedItem.bio || ''}
            onChange={(e) => handleFieldChange('bio', e.target.value)}
            {...disabledProp}
          />
        </label>
  
        {/* If we have an existing profile pic URL and no new file selected, show it */}
        {selectedItem.profile_pic && typeof selectedItem.profile_pic === 'string' && (
          <div className="current-profile-pic">
            <p>Current Profile Picture:</p>
            <img
              src={selectedItem.profile_pic}
              alt="Current Profile"
              style={{ width: '100px', height: '100px', objectFit: 'cover' }}
            />
          </div>
        )}
  
        <label>
          Profile Picture (required if creating):
          <input
            type="file"
            name="uploaded_profile_pic"
            accept="image/*"
            onChange={handleDjProfilePicChange}
            {...disabledProp}
          />
        </label>
  
        {/* If a new profile pic file is selected, show a message */}
        {selectedItem.profile_pic && selectedItem.profile_pic instanceof File && (
          <p>New profile picture selected.</p>
        )}
  
        <label>
          Instagram (optional):
          <input
            type="text"
            value={selectedItem.instagram || ''}
            onChange={(e) => handleFieldChange('instagram', e.target.value)}
            {...disabledProp}
          />
        </label>
  
        <label>
          red (optional):
          <input
            type="text"
            value={selectedItem.red || ''}
            onChange={(e) => handleFieldChange('red', e.target.value)}
            {...disabledProp}
          />
        </label>
  
        <label>
          soundcloud (optional):
          <input
            type="text"
            value={selectedItem.soundcloud || ''}
            onChange={(e) => handleFieldChange('soundcloud', e.target.value)}
            {...disabledProp}
          />
        </label>
  
        <label>
          wechat (optional):
          <input
            type="text"
            value={selectedItem.wechat || ''}
            onChange={(e) => handleFieldChange('wechat', e.target.value)}
            {...disabledProp}
          />
        </label>
      </>
    );
  };

  const renderPerformanceFields = () => {
    const disabledProp = { disabled: readOnly };

    const selectedDjs = selectedItem?.dj || [];
    const selectedDjIds = selectedDjs.map(d => d.id);
    const currentlySelected = selectedItem?.dj_ids_write || selectedDjIds;

    return (
      <>
        {isCreatingNew && (
          <label>
            Event (required):
            <select
              value={selectedItem.event_id_write || ''}
              onChange={(e) => handleFieldChange('event_id_write', e.target.value)}
              {...disabledProp}
            >
              <option value="">Select Event</option>
              {allEvents.map(ev => (
                <option key={ev.id} value={ev.id}>{ev.date} {ev.event_name}</option>
              ))}
            </select>
          </label>
        )}
        {!isCreatingNew && (
          <>
            <label>
              Event Date (read-only):
              <input type="text" value={selectedItem.event_date || ''} readOnly />
            </label>
            <label>
              Event Name (read-only):
              <input type="text" value={selectedItem.event_name || ''} readOnly />
            </label>
          </>
        )}
        <label>
          DJs:
          <select
            multiple
            value={currentlySelected}
            onChange={handleDjSelectionChange}
            {...disabledProp}
          >
            {allDjs.map(dj => (
              <option key={dj.id} value={dj.id}>{dj.name}</option>
            ))}
          </select>
        </label>
        <label>
          Time (required):
          <select
            value={selectedItem.time || ''}
            onChange={(e) => handleFieldChange('time', e.target.value)}
            {...disabledProp}
          >
            <option value="">Select Time</option>
            {predefinedTimes.map(t => (
              <option key={t} value={t}>{t}</option>
            ))}
          </select>
        </label>
        <label>
          Mixer Model (required):
          <select
            value={selectedItem.mixer_model || ''}
            onChange={(e) => handleFieldChange('mixer_model', e.target.value)}
            {...disabledProp}
          >
            <option value="">Select Mixer Model</option>
            {mixerModels.map(mm => (
              <option key={mm.value} value={mm.value}>{mm.label}</option>
            ))}
          </select>
        </label>
        <label>
          Set Type (required):
          <select
            value={(selectedItem.is_live === undefined) ? 'false' : selectedItem.is_live.toString()}
            onChange={(e) => handleFieldChange('is_live', e.target.value === 'true')}
            {...disabledProp}
          >
            <option value="false">DJ Set</option>
            <option value="true">Live Set</option>
          </select>
        </label>
      </>
    );
  };

  const renderFieldsForTab = () => {
    if (!selectedItem && !isCreatingNew) return null;

    if (activeTab === 'performances') {
      return renderPerformanceFields();
    }

    if (activeTab === 'events') {
      return (
        <>
          <label>Event Name (read-only): <input type="text" value={selectedItem.event_name || ''} readOnly /></label>
          <label>Date (YYYY-MM-DD, read-only): <input type="text" value={selectedItem.date || ''} readOnly /></label>
          <label>Week No (read-only): <input type="number" value={selectedItem.week_no || ''} readOnly /></label>
          <label>Program (read-only): <input type="text" value={selectedItem.program || ''} readOnly /></label>
          <label>Color (read-only): <input type="text" value={selectedItem.color || ''} readOnly /></label>
        </>
      );
    }

    if (activeTab === 'djs') {
      return renderDjFields();
    }

    if (activeTab === 'labels') {
      const disabledProp = { disabled: readOnly };
      return (
        <>
          <label>
            Label Name (required):
            <input
              type="text"
              value={selectedItem.label_name || ''}
              onChange={(e) => handleFieldChange('label_name', e.target.value)}
              {...disabledProp}
            />
          </label>
          <label>
            Label Desc (optional):
            <textarea
              value={selectedItem.label_desc || ''}
              onChange={(e) => handleFieldChange('label_desc', e.target.value)}
              {...disabledProp}
            />
          </label>
          <label>
            Label Color (optional):
            <input
              type="text"
              value={selectedItem.label_color || ''}
              onChange={(e) => handleFieldChange('label_color', e.target.value)}
              {...disabledProp}
            />
          </label>
        </>
      );
    }

    if (activeTab === 'partners') {
      const disabledProp = { disabled: readOnly };
      return (
        <>
          <label>
            Name (required):
            <input
              type="text"
              value={selectedItem.name || ''}
              onChange={(e) => handleFieldChange('name', e.target.value)}
              {...disabledProp}
            />
          </label>
          <label>
            Desc (optional):
            <textarea
              value={selectedItem.desc || ''}
              onChange={(e) => handleFieldChange('desc', e.target.value)}
              {...disabledProp}
            />
          </label>
          <label>
            Color (optional):
            <input
              type="text"
              value={selectedItem.color || ''}
              onChange={(e) => handleFieldChange('color', e.target.value)}
              {...disabledProp}
            />
          </label>
        </>
      );
    }

    if (activeTab === 'locations') {
      const disabledProp = { disabled: readOnly };
      return (
        <>
          <label>
            Location Name (required):
            <input
              type="text"
              value={selectedItem.location_name || ''}
              onChange={(e) => handleFieldChange('location_name', e.target.value)}
              {...disabledProp}
            />
          </label>
          <label>
            Address (optional):
            <input
              type="text"
              value={selectedItem.location_addr || ''}
              onChange={(e) => handleFieldChange('location_addr', e.target.value)}
              {...disabledProp}
            />
          </label>
        </>
      );
    }

    return null;
  };

  return (
    <div className="operation-admin-container">
      {loadingSets && (
        <div className="loading-overlay">
          <img src="static/img/loading.png" alt="Loading" className="loading-gif" />
        </div>
      )}
      <h2>Operation Admin</h2>
      <div className="tabs-bar">
        {TABS.map(tab => (
          <button
            key={tab.key}
            className={`tab-button ${activeTab === tab.key ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {loading && <div className="loading-indicator">Loading...</div>}
      {!loading && (
        <div className="admin-content">
          <div className="sidebar">
            <div className="sidebar-header">
              <h3>{TABS.find(t=>t.key===activeTab)?.label} List</h3>
              {canCreate && (
                <button className="create-button" onClick={handleCreateNew}>Create New</button>
              )}
            </div>
            <div className="search-container">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <ul className="item-list">
              {filteredItems && filteredItems.length === 0 && (
                <li className="no-items">No items found.</li>
              )}
              {(filteredItems || []).map(item => (
                <li
                  key={item.id}
                  className={selectedItem?.id === item.id ? 'selected' : ''}
                  onClick={() => handleSelectItem(item)}
                >
                  {renderListItem(item)}
                </li>
              ))}
            </ul>
          </div>
          <div className="detail-panel">
            <h3>Details</h3>
            {renderInstructionForTab()}
            {(selectedItem || isCreatingNew) ? (
              <div className="detail-form">
                {renderFieldsForTab()}
                <div className="buttons-row">
                  {
                    (isCreatingNew || isEditing) && activeTab !== 'events' && (
                      <>
                        <button className="save-button" onClick={handleSave}>
                          {isCreatingNew ? 'Create Item' : 'Save Changes'}
                        </button>
                        <button className="cancel-button" onClick={handleCancelEdit}>Cancel</button>
                      </>
                    )
                  }

                  {
                    (selectedItem && !isEditing && !isCreatingNew && activeTab !== 'events') && (
                      <button className="edit-button" onClick={handleEditMode}>Edit</button>
                    )
                  }

                  {activeTab === 'events' && selectedItem && (
                    <p className="read-only-hint">Events are read-only.</p>
                  )}
                </div>
              </div>
            ) : (
              <p>Select an item to view details or click "Create New" to add a new one.</p>
            )}
          </div>
        </div>
      )}

      {showCropper && (
        <div className="cropper-modal">
          <div className="cropper-wrapper">
            <div className="cropper-container">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
            <div className="cropper-controls">
              <div className="slider-container">
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  onChange={(e, z) => setZoom(z)}
                />
              </div>
              <div className="button-group">
                <button onClick={showCroppedImage}>Crop Image</button>
                <button onClick={() => setShowCropper(false)}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OperationAdmin;
