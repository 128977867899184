// MusicPlayer.js

import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';

const MusicPlayer = ({ audioSrc, selectedSet, djInfo }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [cuePoint, setCuePoint] = useState(0);
  const audioRef = useRef(null);
  const prevAudioSrc = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);

  const formatTime = (seconds) => {
    if (!Number.isFinite(seconds)) {
      return '00:00';
    }

    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const formattedH = h > 0 ? `${h.toString().padStart(2, '0')}:` : '';
    const formattedM = m.toString().padStart(2, '0');
    const formattedS = s.toString().padStart(2, '0');

    return `${formattedH}${formattedM}:${formattedS}`;
  };

  useEffect(() => {
    const audio = audioRef.current;

    if (audioSrc && audioSrc !== prevAudioSrc.current) {
      setCuePoint(0);
      setProgress(0);
      setCurrentTime(0);
      prevAudioSrc.current = audioSrc;

      // Pause the audio first
      audio.pause();

      // Load the new audio source
      audio.src = audioSrc;
      audio.load();

      // Play the audio when it's ready
      const playAudio = () => {
        audio.play().then(() => {
          setIsPlaying(true);
        }).catch((error) => {
          console.error('Error playing audio:', error);
        });
      };

      // Update the progress bar and currentTime
      const updateProgress = () => {
        if (audio.duration && Number.isFinite(audio.duration) && audio.duration > 0) {
          const percentage = (audio.currentTime / audio.duration) * 100;
          setProgress(percentage);
          setCurrentTime(audio.currentTime);
        } else {
          setProgress(0);
          setCurrentTime(0);
        }
      };

      audio.addEventListener('canplaythrough', playAudio);
      audio.addEventListener('timeupdate', updateProgress);

      return () => {
        audio.removeEventListener('canplaythrough', playAudio);
        audio.removeEventListener('timeupdate', updateProgress);
      };
    }
  }, [audioSrc]);

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
      setIsPlaying(false);
    } else {
      audio.play().then(() => {
        setIsPlaying(true);
      }).catch((error) => {
        console.error('Error playing audio:', error);
      });
    }
  };

  const handleCueClick = () => {
    const audio = audioRef.current;
    if (!isPlaying) {
      setCuePoint(audio.currentTime);
    } else {
      audio.currentTime = cuePoint;
      setProgress((cuePoint / audio.duration) * 100);
      setCurrentTime(cuePoint);
    }
  };

  const handleProgressChange = (e) => {
    const manualChange = Number(e.target.value);
    const audio = audioRef.current;

    if (audio.duration && Number.isFinite(audio.duration) && audio.duration > 0) {
      const newTime = (audio.duration / 100) * manualChange;
      audio.currentTime = newTime;
      setProgress(manualChange);
      setCurrentTime(newTime);
    } else {
      setProgress(0);
      setCurrentTime(0);
    }
  };

  return (
    <div className="music-player">
      <Helmet>
        <title>
          {isPlaying && selectedSet
            ? `byyb.radio 🔊 ${djInfo.map(dj => dj.name).join(', ')} @ ${selectedSet.program} ${selectedSet.date}`
            : 'byyb.radio - A Community Radio in the heart of Shanghai, China'}
        </title>
      </Helmet>

      {selectedSet && (
        <div className="set-info">
          {djInfo && djInfo.length > 0 ? (
            <ul className="set-info-main">
              {djInfo.map((dj) => (
                <li key={dj.id}>{dj.name}</li>
              ))}
            </ul>
          ) : (
            <p>No DJ</p>
          )}
          <ul>
            <li className="set-info-secondary">{selectedSet.date}</li>
            <li className="set-info-secondary">{selectedSet.program}</li>
          </ul>
        </div>
      )}

      <audio ref={audioRef} />

      {/* CUE Button */}
      <button className="cue-btn" onClick={handleCueClick} aria-label="CUE">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="black"
          xmlns="http://www.w3.org/2000/svg"
        >
          <text
            x="12"
            y="16"
            textAnchor="middle"
            fontSize="12"
            fill="black"
            fontFamily="Arial, sans-serif"
          >
            CUE
          </text>
        </svg>
      </button>

      {/* Play/Pause Button */}
      <button className="play-pause-btn" onClick={handlePlayPause}>
        {isPlaying ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="6" y="5" width="4" height="14" />
            <rect x="14" y="5" width="4" height="14" />
          </svg>
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon points="6,4 20,12 6,20" />
          </svg>
        )}
      </button>

      <div className="progress-container">
        <input
          type="range"
          value={isNaN(progress) ? 0 : progress}
          onChange={handleProgressChange}
          style={{ width: '100%' }}
        />
        {audioRef.current && Number.isFinite(audioRef.current.duration) && cuePoint > 0 && (
          <div
            className="cue-point-marker"
            style={{
              left: `${(cuePoint / audioRef.current.duration) * 100}%`,
            }}
          ></div>
        )}
      </div>

      <span className="music-player-timer">
        {formatTime(currentTime)} / {formatTime(audioRef.current?.duration || 0)}
      </span>
    </div>
  );
};

export default MusicPlayer;
