import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import './DjProfile.css';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import { toast } from 'react-toastify';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import getCroppedImg from './cropImage'; // Ensure this path is correct

const DjProfile = () => {
  const { isAuthenticated, userRoles, hasDjProfile, fetchAuthStatus } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate(); // Initialize the navigate hook

  // Language state: 'zh' for Chinese, 'en' for English
  const [language, setLanguage] = useState('zh'); 

  const translations = {
    zh: {
      pageTitle: '你的艺人简介',
      noProfile: '你还没有艺人简介，创建一个来进行电台预约！',
      fillInfoFromBooking: '请先填写您的艺人信息。',
      loadingProfile: '加载简介中...',
      pleaseLogin: '请登录查看你的简介。',
      editProfile: '编辑个人信息',
      cancel: '取消',
      saveChanges: '保存修改',
      createProfile: '创建简介',
      stageNameRequired: '舞台名是必需的。',
      bioRequired: '个人简介是必需的。',
      profilePicRequired: '艺人图片是必需的。',
      profileCreated: '简介已成功创建。',
      profileUpdated: '简介已成功更新。',
      stageName: '舞台名 (Stage Name)',
      callName: '称呼 (Call Name)',
      bio: '个人简介 (Bio)',
      profilePic: '艺人图片 (Profile Picture)',
      instagram: 'Instagram',
      red: '小红书 (Red)',
      soundcloud: 'SoundCloud',
      bandcamp: 'Bandcamp',
      wechat: 'WeChat ID (紧急联系用)',
      leaveBlank: '如果没有，可留空',
      cropImage: '裁剪图片',
      cropCancel: '取消',
      enTranslate: 'EN',
      zhTranslate: '中',
      bookingSession: '预约电台', // Add translation for Booking a Session
    },
    en: {
      pageTitle: 'Your Artist Profile',
      noProfile: "You don't have an Artist profile yet, create one to book sessions!",
      fillInfoFromBooking: 'Please fill in your artist info first.',
      loadingProfile: 'Loading profile...',
      pleaseLogin: 'Please log in to view your profile.',
      editProfile: 'Edit Profile',
      cancel: 'Cancel',
      saveChanges: 'Save Changes',
      createProfile: 'Create Profile',
      stageNameRequired: 'Stage Name is required.',
      bioRequired: 'Bio is required.',
      profilePicRequired: 'Profile Picture is required.',
      profileCreated: 'Profile created successfully.',
      profileUpdated: 'Profile updated successfully.',
      stageName: 'Stage Name',
      callName: 'Call Name',
      bio: 'Bio',
      profilePic: 'Profile Picture',
      instagram: 'Instagram',
      red: 'Red',
      soundcloud: 'SoundCloud',
      bandcamp: 'Bandcamp',
      wechat: 'WeChat ID (in case we need to contact you)',
      leaveBlank: 'Leave Blank if it doesn\'t apply',
      cropImage: 'Crop Image',
      cropCancel: 'Cancel',
      enTranslate: 'EN',
      zhTranslate: '中',
      bookingSession: 'Book a Session!', // English translation
    },
  };

  const [profile, setProfile] = useState({
    name: '',
    call_name: '',
    bio: '',
    profile_pic: null,
    existing_profile_pic: '',
    instagram: '',
    red: '',
    soundcloud: '',
    bandcamp: '',
    wechat: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const isCreating = !hasDjProfile;

  // Crop states
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  useEffect(() => {
    if (location.state && location.state.fromBooking) {
      toast.info(translations[language].fillInfoFromBooking);
    }
  }, [location, language, translations]);

  useEffect(() => {
    const fetchProfile = async () => {
      if (hasDjProfile) {
        try {
          const response = await axios.get('/api/dj/profile/', {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          });
          setProfile({
            name: response.data.name || '',
            call_name: response.data.call_name || '',
            bio: response.data.bio || '',
            profile_pic: null,
            existing_profile_pic: response.data.profile_pic || '',
            instagram: response.data.instagram || '',
            red: response.data.red || '',
            soundcloud: response.data.soundcloud || '',
            bandcamp: response.data.bandcamp || '',
            wechat: response.data.wechat || '',
          });
          setIsEditing(false);
        } catch (err) {
          setError('Failed to fetch profile.');
        } finally {
          setLoading(false);
        }
      } else {
        // User doesn't have a DJ profile
        setIsEditing(true);
        setLoading(false);
      }
    };
  
    // Replace `userRole` checks with `userRoles` checks
    if (isAuthenticated && (userRoles.includes('dj') || userRoles.includes('superuser'))) {
      fetchProfile();
    } else {
      setLoading(false);
    }
  }, [isAuthenticated, userRoles, hasDjProfile]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      const file = files[0];
      if (file) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setImageSrc(reader.result);
          setShowCropper(true);
        });
        reader.readAsDataURL(file);
      }
    } else {
      setProfile({ ...profile, [name]: value });
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImageBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      const file = new File([croppedImageBlob], 'croppedImage.jpg', { type: 'image/jpeg' });

      setCroppedImage(file);
      setShowCropper(false);

      setProfile((prevProfile) => ({
        ...prevProfile,
        profile_pic: file,
        existing_profile_pic: URL.createObjectURL(croppedImageBlob),
      }));
    } catch (e) {
      console.error(e);
      toast.error('Failed to crop the image.');
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    let validationError = null;
    if (!profile.name.trim()) {
      validationError = translations[language].stageNameRequired;
    } else if (!profile.bio.trim()) {
      validationError = translations[language].bioRequired;
    } else if (isCreating && !profile.profile_pic) {
      validationError = translations[language].profilePicRequired;
    }

    if (validationError) {
      toast.error(validationError);
      return;
    }

    const formData = new FormData();
    if (profile.profile_pic) {
      formData.append('uploaded_profile_pic', profile.profile_pic);
    }

    for (const key in profile) {
      if (key !== 'existing_profile_pic' && profile[key] !== null) {
        formData.append(key, profile[key]);
      }
    }

    try {
      if (isCreating) {
        // Create new DJ profile
        await axios.post('/api/dj/profile/create/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });
        toast.success(translations[language].profileCreated);
        await fetchAuthStatus();
      } else {
        // Update existing DJ profile
        await axios.put('/api/dj/profile/update/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });
        toast.success(translations[language].profileUpdated);
      }
      setError('');
      setIsEditing(false);
    } catch (err) {
      const errorMsg =
        err.response?.data?.name?.[0] ||
        err.response?.data?.call_name?.[0] ||
        err.response?.data?.bio?.[0] ||
        err.response?.data?.profile_pic?.[0] ||
        err.response?.data?.instagram?.[0] ||
        err.response?.data?.red?.[0] ||
        err.response?.data?.soundcloud?.[0] ||
        err.response?.data?.bandcamp?.[0] ||
        'Error updating profile.';
      setError(errorMsg);
      toast.error(errorMsg);
    }
  };

  // Toggle language
  const toggleLanguage = () => {
    setLanguage((prev) => (prev === 'zh' ? 'en' : 'zh'));
  };

  if (loading) return <div>{translations[language].loadingProfile}</div>;
  if (!isAuthenticated) return <div>{translations[language].pleaseLogin}</div>;
  if (error && !isEditing) return <div className="error">{error}</div>;

  return (
    <div className="dj-profile-container">
      {/* Language Toggle Button */}
      <button className="translate-button" onClick={toggleLanguage}>
        {language === 'zh' ? translations.zh.enTranslate : translations.en.zhTranslate}
      </button>

      <h2>{translations[language].pageTitle}</h2>

      {isCreating && !loading && (
        <div style={{ marginBottom: '20px' }}>
          <p>{translations[language].noProfile}</p>
        </div>
      )}

      {!isEditing && !isCreating ? (
        <div className="profile-view">
          <div className="profile-details">
            <img
              src={
                profile.existing_profile_pic
                  ? profile.existing_profile_pic
                  : '/static/default_profile.png'
              }
              alt={`${profile.name}'s Profile`}
              className="profile-pic"
            />
            <p><strong>{profile.name}</strong></p>
            {profile.bio && <p>{profile.bio}</p>}
            <br></br>
            {profile.instagram && (
              <p>
                {translations[language].instagram}: <a href={`https://instagram.com/${profile.instagram}`} target="_blank" rel="noopener noreferrer">{profile.instagram}</a>
              </p>
            )}
            {profile.red && <p>{translations[language].red}: {profile.red}</p>}
            {profile.soundcloud && (
              <p>
                {translations[language].soundcloud}: <a href={`https://soundcloud.com/${profile.soundcloud}`} target="_blank" rel="noopener noreferrer">{profile.soundcloud}</a>
              </p>
            )}
            {profile.bandcamp && (
              <p>
                {translations[language].bandcamp}: <a href={`https://bandcamp.com/${profile.bandcamp}`} target="_blank" rel="noopener noreferrer">{profile.bandcamp}</a>
              </p>
            )}
            {profile.wechat && <p>{translations[language].wechat}: {profile.wechat}</p>}
          </div>

          <div className="button-group">
            {/* New Booking Button */}
            <button 
              onClick={() => navigate('/booking', { state: { startBooking: true } })} 
              className="booking-button"
              style={{ marginRight: '10px' }}
            >
              {translations[language].bookingSession}
            </button>
            
            {/* Edit Profile Button */}
            <button onClick={handleEditToggle} className="edit-button">
              {translations[language].editProfile}
            </button>
          </div>

          {error && <p className="error">{error}</p>}
        </div>
      ) : (
        <div className="profile-edit">
          <form onSubmit={handleSubmit}>
            {profile.existing_profile_pic && (
              <div className="current-profile-pic">
                <img
                  src={profile.existing_profile_pic}
                  alt="Current Profile"
                  className="profile-pic-preview"
                />
              </div>
            )}

            <label>
              {translations[language].stageName}
              <input
                type="text"
                name="name"
                value={profile.name}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              {translations[language].bio}:
              <textarea
                name="bio"
                value={profile.bio}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              {translations[language].profilePic}:
              <input
                type="file"
                name="profile_pic"
                onChange={handleChange}
                accept="image/*"
                required={isCreating} 
              />
            </label>
            <label>
              {translations[language].instagram}:
              <input
                type="text"
                name="instagram"
                value={profile.instagram}
                onChange={handleChange}
                placeholder={translations[language].leaveBlank}
              />
            </label>
            <label>
              {translations[language].red}:
              <input
                type="text"
                name="red"
                value={profile.red}
                onChange={handleChange}
                placeholder={translations[language].leaveBlank}
              />
            </label>
            <label>
              {translations[language].soundcloud}:
              <input
                type="text"
                name="soundcloud"
                value={profile.soundcloud}
                onChange={handleChange}
                placeholder={translations[language].leaveBlank}
              />
            </label>
            <label>
              {translations[language].wechat}:
              <input
                type="text"
                name="wechat"
                value={profile.wechat || ''}
                onChange={handleChange}
              />
            </label>
            <div className="form-buttons">
              <button type="submit" className="save-button">
                {isCreating ? translations[language].createProfile : translations[language].saveChanges}
              </button>
              {!isCreating && (
                <button
                  type="button"
                  onClick={handleEditToggle}
                  className="cancel-button"
                >
                  {translations[language].cancel}
                </button>
              )}
            </div>
          </form>
          {error && <p className="error">{error}</p>}
        </div>
      )}

      {/* Cropper Modal */}
      {showCropper && (
        <div className="cropper-modal">
          <div className="cropper-wrapper">
            <div className="cropper-container">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
            <div className="cropper-controls">
              <div className="slider-container">
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </div>
              <div className="button-group">
                <button onClick={showCroppedImage}>{translations[language].cropImage}</button>
                <button onClick={() => setShowCropper(false)}>{translations[language].cropCancel}</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DjProfile;
