// src/index.js

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { AuthProvider } from './AuthContext'; // Import AuthProvider
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

/**
 * Helper function to retrieve the CSRF token from cookies.
 * Django sets the CSRF token in a cookie named 'csrftoken'.
 */
const getCsrfToken = () => {
  const name = 'csrftoken';
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    cookie = cookie.trim();
    if (cookie.startsWith(name + '=')) {
      return decodeURIComponent(cookie.substring(name.length + 1));
    }
  }
  return '';
};

/**
 * Axios Configuration for Session-Based Authentication
 */

// 1. Set the base URL for Axios requests.
//    Ensure that REACT_APP_API_BASE_URL is set in your .env file.
//    If not set, it defaults to 'http://localhost:8000'.
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';

// 2. Enable sending cookies with every request.
//    This is essential for session-based authentication.
axios.defaults.withCredentials = true;

// 3. Add a request interceptor to include the CSRF token in headers.
//    This ensures that all state-changing requests (POST, PUT, DELETE, PATCH)
//    include the necessary CSRF token for Django to validate the request.
axios.interceptors.request.use(
  (config) => {
    const csrfToken = getCsrfToken();
    console.log('Axios interceptor: CSRF Token:', csrfToken); // Debugging
    // Only attach the CSRF token to state-changing requests
    if (
      csrfToken &&
      ['post', 'put', 'delete', 'patch'].includes(config.method.toLowerCase())
    ) {
      config.headers['X-CSRFToken'] = csrfToken;
      console.log('Axios interceptor: Attached X-CSRFToken header'); // Debugging
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Function to fetch CSRF token from the backend
 */
const fetchCsrfToken = async () => {
  try {
    const response = await axios.get('/api/get_csrf_token/');
    console.log('CSRF token fetched and cookie set:', response.data); // Debugging
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
  }
};

/**
 * Root Component
 */
const Root = () => {
  useEffect(() => {
    // Fetch CSRF token when the app loads
    fetchCsrfToken();
  }, []);

  return (
    <AuthProvider>
      <BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
          pauseOnHover
          draggable={false}
          theme="colored"
        />
        <App />
      </BrowserRouter>
    </AuthProvider>
  );
};

/**
 * Render the React Application
 */

// 5. Create a root element for React.
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

// 6. Render the App component wrapped with BrowserRouter for routing.
root.render(<Root />);

// 7. Optional: Measure performance in your app.
reportWebVitals();
