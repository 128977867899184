import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './AvailableSlotsAdmin.css';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import getCroppedImg from './cropImage';

const AvailableSlotsAdmin = () => {
  // State variables
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [eventDetails, setEventDetails] = useState(null);
  const [eventName, setEventName] = useState('');
  const [weekNo, setWeekNo] = useState('');
  const [program, setProgram] = useState('rs');
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [color, setColor] = useState('#ffffff');
  const [isSuperuser, setIsSuperuser] = useState(false);
  const [performances, setPerformances] = useState([]);
  const [isEditingEvent, setIsEditingEvent] = useState(false);

  // New state for event bg and associations
  const [eventBgFile, setEventBgFile] = useState(null);
  const [labels, setLabels] = useState([]);
  const [partners, setPartners] = useState([]);
  const [locations, setLocations] = useState([]);

  const [showLabelFields, setShowLabelFields] = useState(false);
  const [showPartnerFields, setShowPartnerFields] = useState(false);

  const [selectedLabels, setSelectedLabels] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  // Creating new associations
  const [creatingNewLabel, setCreatingNewLabel] = useState(false);
  const [creatingNewPartner, setCreatingNewPartner] = useState(false);
  const [creatingNewLocation, setCreatingNewLocation] = useState(false);

  const [newLabelData, setNewLabelData] = useState({});
  const [newPartnerData, setNewPartnerData] = useState({});
  const [newLocationData, setNewLocationData] = useState({});

  // Image cropping
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [showCropper, setShowCropper] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  // Logo cropping
  const [logoCrop, setLogoCrop] = useState({ x: 0, y: 0 });
  const [logoZoom, setLogoZoom] = useState(1);
  const [logoCroppedAreaPixels, setLogoCroppedAreaPixels] = useState(null);
  const [showLogoCropper, setShowLogoCropper] = useState(false);
  const [logoImageSrc, setLogoImageSrc] = useState(null);
  const [currentLogoField, setCurrentLogoField] = useState('');
  const [croppedLabelLogo, setCroppedLabelLogo] = useState(null);
  const [croppedPartnerLogo, setCroppedPartnerLogo] = useState(null);
  const [croppedLocationLogo, setCroppedLocationLogo] = useState(null);

  // For highlighting events on the calendar
  const [allEvents, setAllEvents] = useState([]);

  // Time options
  const timeOptions = [];
  for (let hour = 12; hour <= 22; hour++) {
    timeOptions.push(`${hour.toString().padStart(2, '0')}:00`);
    timeOptions.push(`${hour.toString().padStart(2, '0')}:30`);
  }

  const eventTypes = [
    { value: 'rs', label: 'byyb.radio' },
    { value: 'ia', label: 'ivy arum session' },
    { value: 'hr', label: 'heimliday radio' },
    { value: 'bo', label: 'byyb.out' },
  ];

  useEffect(() => {
    fetchAvailableSlots();
    fetchAuthStatus();
    fetchLabels();
    fetchPartners();
    fetchLocations();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      fetchEventDetails(selectedDate);
    }
  }, [selectedDate]);

  const fetchAuthStatus = async () => {
    try {
      const response = await axios.get('/api/auth/status/');
      setIsSuperuser(response.data.role === 'superuser');
    } catch (error) {
      console.error('Error fetching auth status:', error);
    }
  };

  const fetchAvailableSlots = async () => {
    try {
      const response = await axios.get('/api/available_slots/');
      setAvailableSlots(response.data);
      setAllEvents(response.data);
    } catch (error) {
      console.error('Error fetching available slots:', error);
    }
  };

  const fetchLabels = async () => {
    try {
      const response = await axios.get('/api/labels/');
      setLabels(response.data);
    } catch (error) {
      console.error('Error fetching labels:', error);
    }
  };

  const fetchPartners = async () => {
    try {
      const response = await axios.get('/api/partners/');
      setPartners(response.data);
    } catch (error) {
      console.error('Error fetching partners:', error);
    }
  };

  const fetchLocations = async () => {
    try {
      const response = await axios.get('/api/locations/');
      setLocations(response.data);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const fetchPerformances = async (date) => {
    try {
      const formattedDate = formatDate(date);
      const response = await axios.get('/api/performances/timeslots/', {
        params: { date: formattedDate },
      });
      setPerformances(response.data);
    } catch (error) {
      console.error('Error fetching performances:', error);
      setPerformances([]);
    }
  };

  const fetchEventDetails = async (date) => {
    try {
      const formattedDate = formatDate(date);
      const response = await axios.get('/api/available_slots/', {
        params: { date: formattedDate },
      });
      const eventsForDate = response.data.filter(
        (event) => event.date === formattedDate
      );
      if (eventsForDate.length > 0) {
        setEventDetails(eventsForDate[0]);
        setColor(eventsForDate[0].color || '#ffffff');
        fetchPerformances(date);
      } else {
        setEventDetails(null);
        setColor('#ffffff');
        setPerformances([]);
      }
    } catch (error) {
      console.error('Error fetching event details:', error);
      setEventDetails(null);
      setColor('#ffffff');
      setPerformances([]);
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const toggleSlotSelection = (slot) => {
    if (selectedSlots.includes(slot)) {
      setSelectedSlots(selectedSlots.filter((s) => s !== slot));
    } else {
      setSelectedSlots([...selectedSlots, slot]);
    }
  };

  const handleEventBgFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setEventBgFile(file);
      const imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
      setShowCropper(true);
    }
  };

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImageBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      setCroppedImage(croppedImageBlob);
      setShowCropper(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddSlots = async () => {
    if (!eventDetails && (!eventName || !weekNo)) {
      alert('Please fill in all event details before adding slots.');
      return;
    }

    if (!selectedSlots.length) {
      alert('Please select at least one timeslot.');
      return;
    }

    const formData = new FormData();
    formData.append('date', formatDate(selectedDate));
    formData.append('event_name', eventName || eventDetails?.event_name);
    formData.append('week_no', parseInt(weekNo || eventDetails?.week_no, 10));
    formData.append('program', program || eventDetails?.program);
    formData.append('color', color || eventDetails?.color || '#ffffff');

    selectedSlots.forEach((slot, index) => {
      formData.append(`timeslots[${index}]`, slot);
    });

    if (croppedImage) {
      formData.append('event_bg_file', croppedImage, 'event_bg.jpg');
    }

    // Labels
    if (showLabelFields) {
      const labelsData = [];
      if (creatingNewLabel && newLabelData.label_name) {
        labelsData.push(newLabelData);
        if (croppedLabelLogo) {
          formData.append('label_logo', croppedLabelLogo, 'label_logo.jpg');
        }
      }
      selectedLabels.forEach((labelId) => {
        labelsData.push({ id: labelId });
      });
      if (labelsData.length > 0) {
        formData.append('label_input', JSON.stringify(labelsData));
      }
    }

    // Partners
    if (showPartnerFields) {
      const partnersData = [];
      if (creatingNewPartner && newPartnerData.name) {
        partnersData.push(newPartnerData);
        if (croppedPartnerLogo) {
          formData.append('partner_logo', croppedPartnerLogo, 'partner_logo.jpg');
        }
      }
      selectedPartners.forEach((partnerId) => {
        partnersData.push({ id: partnerId });
      });
      if (partnersData.length > 0) {
        formData.append('partner_input', JSON.stringify(partnersData));
      }
    }

    // Locations
    if (creatingNewLocation && newLocationData.location_name) {
      const locationsData = [newLocationData];
      if (croppedLocationLogo) {
        formData.append('location_logo', croppedLocationLogo, 'location_logo.jpg');
      }
      formData.append('location_input', JSON.stringify(locationsData));
    } else if (!creatingNewLocation && selectedLocations.length > 0) {
      const locationsData = selectedLocations.map((locId) => ({ id: locId }));
      formData.append('location_input', JSON.stringify(locationsData));
    }

    try {
      const response = await axios.post('/api/available_slots/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      alert('Slots added successfully.');
      // Reset
      setEventName('');
      setWeekNo('');
      setProgram('rs');
      setSelectedSlots([]);
      setColor('#ffffff');
      setEventBgFile(null);
      setCroppedImage(null);
      setImageSrc(null);
      setSelectedLabels([]);
      setSelectedPartners([]);
      setSelectedLocations([]);
      setNewLabelData({});
      setNewPartnerData({});
      setNewLocationData({});
      setCreatingNewLabel(false);
      setCreatingNewPartner(false);
      setCreatingNewLocation(false);
      setShowLabelFields(false);
      setShowPartnerFields(false);
      setCroppedLabelLogo(null);
      setCroppedPartnerLogo(null);
      setCroppedLocationLogo(null);

      fetchAvailableSlots();
      fetchEventDetails(selectedDate);
    } catch (error) {
      console.error('Error adding slots:', error);
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.detail || 'Error adding slots.';
        alert(`Error: ${errorMessage}`);
      } else {
        alert('Error adding slots.');
      }
    }
  };

  const handleDeletePerformance = async (performanceId) => {
    if (!window.confirm('Are you sure you want to delete this timeslot?')) {
      return;
    }

    try {
      const response = await axios.delete(
        `/api/performances/delete/${performanceId}/`
      );
      alert(response.data.detail || 'Timeslot deleted successfully.');
      fetchPerformances(selectedDate);
      fetchAvailableSlots();
      if (response.data.detail && response.data.detail.includes('deleted')) {
        setEventDetails(null);
        setColor('#ffffff');
      }
    } catch (error) {
      console.error('Error deleting timeslot:', error);
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.detail || 'Error deleting timeslot.';
        alert(`Error: ${errorMessage}`);
      } else {
        alert('Error deleting timeslot.');
      }
    }
  };

  const handleLogoFileChange = async (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const imageDataUrl = await readFile(file);
      setLogoImageSrc(imageDataUrl);
      setShowLogoCropper(true);
      setCurrentLogoField(type);
      if (type === 'label') {
        setNewLabelData({ ...newLabelData, logoFile: file });
      } else if (type === 'partner') {
        setNewPartnerData({ ...newPartnerData, logoFile: file });
      } else if (type === 'location') {
        setNewLocationData({ ...newLocationData, logoFile: file });
      }
    }
  };

  const handleLabelChange = (e) => {
    const values = Array.from(e.target.selectedOptions, (option) => option.value);
    if (values.includes('new')) {
      setCreatingNewLabel(true);
      setSelectedLabels(values.filter((value) => value !== 'new'));
    } else {
      setCreatingNewLabel(false);
      setSelectedLabels(values);
    }
  };

  const handlePartnerChange = (e) => {
    const values = Array.from(e.target.selectedOptions, (option) => option.value);
    if (values.includes('new')) {
      setCreatingNewPartner(true);
      setSelectedPartners(values.filter((value) => value !== 'new'));
    } else {
      setCreatingNewPartner(false);
      setSelectedPartners(values);
    }
  };

  const handleLocationChange = (e) => {
    const values = Array.from(e.target.selectedOptions, (option) => option.value);
    if (values.includes('new')) {
      setCreatingNewLocation(true);
      setSelectedLocations(values.filter((value) => value !== 'new'));
    } else {
      setCreatingNewLocation(false);
      setSelectedLocations(values);
    }
  };

  const handleDownloadGraphics = async () => {
    if (eventDetails && eventDetails.id) {
      try {
        const response = await axios.get(`/api/events/${eventDetails.id}/download_graphics/`, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const contentDisposition = response.headers['content-disposition'] || response.headers['Content-Disposition'];
        let fileName = 'graphic_materials.zip';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
          if (fileNameMatch && fileNameMatch.length >= 2) {
            fileName = fileNameMatch[1];
          }
        }
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading graphic materials:', error);
        alert('Error downloading graphic materials.');
      }
    } else {
      alert('No graphic materials available to download.');
    }
  };

  const onLogoCropComplete = (croppedArea, croppedAreaPixels) => {
    setLogoCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedLogoImage = async () => {
    try {
      const croppedImageBlob = await getCroppedImg(
        logoImageSrc,
        logoCroppedAreaPixels
      );
      if (currentLogoField === 'label') {
        setCroppedLabelLogo(croppedImageBlob);
      } else if (currentLogoField === 'partner') {
        setCroppedPartnerLogo(croppedImageBlob);
      } else if (currentLogoField === 'location') {
        setCroppedLocationLogo(croppedImageBlob);
      }
      setShowLogoCropper(false);
      setLogoImageSrc(null);
      setCurrentLogoField('');
      setLogoCrop({ x: 0, y: 0 });
      setLogoZoom(1);
    } catch (e) {
      console.error(e);
    }
  };

  // New functions for editing and deleting the entire event
  const handleUpdateEvent = async () => {
    if (!eventDetails) return;

    const formData = new FormData();
    // Use existing event date since date should not change
    formData.append('date', eventDetails.date);
    formData.append('event_name', eventName || eventDetails.event_name);
    formData.append('week_no', parseInt(weekNo || eventDetails.week_no, 10));
    formData.append('program', program || eventDetails.program);
    formData.append('color', color || eventDetails.color || '#ffffff');

    // If updating background image
    if (croppedImage) {
      formData.append('event_bg_file', croppedImage, 'event_bg.jpg');
    }

    // If needed, handle labels, partners, locations similarly as in handleAddSlots
    // For simplicity, we won't re-implement full logic here. You can replicate logic similar to handleAddSlots if you want to update them as well.

    try {
      await axios.put(`/api/events/${eventDetails.id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Event updated successfully.');
      setIsEditingEvent(false);
      fetchAvailableSlots();
      fetchEventDetails(selectedDate);
    } catch (error) {
      console.error('Error updating event:', error);
      alert('Error updating event.');
    }
  };

  const handleDeleteEvent = async () => {
    if (!eventDetails || !window.confirm('Are you sure you want to delete this event?')) return;
  
    try {
      const response = await axios.delete(`/api/events/${eventDetails.id}/`);
      alert(response.data.detail || 'Event deleted successfully.');
      setEventDetails(null);
      setEventName('');
      setWeekNo('');
      setProgram('rs');
      setColor('#ffffff');
      fetchAvailableSlots();
    } catch (error) {
      console.error('Error deleting event:', error);
      alert('Error deleting event.');
    }
  };

  return (
    <div className="admin-container">
      <h2>Available Slots Admin</h2>
      <div className="admin-content">
        <div className="left-panel">
          <h3>Select Date</h3>
          <Calendar
            onChange={setSelectedDate}
            value={selectedDate}
            tileContent={({ date, view }) => {
              if (view === 'month') {
                const formatted = formatDate(date);
                const eventForDay = allEvents.find(ev => ev.date === formatted);
                if (eventForDay) {
                  return (
                    <div 
                      style={{
                        width: '100%', 
                        height: '100%', 
                        backgroundColor: eventForDay.color || '#fff'
                      }}
                    ></div>
                  );
                }
              }
              return null;
            }}
          />
        </div>
        <div className="right-panel">
          {eventDetails ? (
            <div className="existing-event-section">
              <h3>Existing Event Details</h3>
              {!isEditingEvent ? (
                <>
                  <p><strong>Date:</strong> {eventDetails.date}</p>
                  <p><strong>Event Name:</strong> {eventDetails.event_name}</p>
                  <p><strong>Week Number:</strong> {eventDetails.week_no}</p>
                  <p><strong>Program:</strong> {eventDetails.program_display}</p>
                  <p><strong>Color:</strong> {eventDetails.color || '#ffffff'}</p>

                  {eventDetails.label && eventDetails.label.length > 0 && (
                    <p><strong>Label:</strong> {eventDetails.label.map((l) => l.label_name).join(', ')}</p>
                  )}
                  {eventDetails.partner && eventDetails.partner.length > 0 && (
                    <p><strong>Partner:</strong> {eventDetails.partner.map((p) => p.name).join(', ')}</p>
                  )}
                  {eventDetails.location && eventDetails.location.length > 0 && (
                    <p><strong>Location:</strong> {eventDetails.location.map((lo) => lo.location_name).join(', ')}</p>
                  )}

                  {eventDetails.event_poster && (
                    <div className="event-poster">
                      <img src={eventDetails.event_poster} alt="Event Poster" loading="lazy" />
                    </div>
                  )}

                  {performances.length > 0 && (
                    <div className="existing-performances">
                      <h4>Existing Performances</h4>
                      <ul>
                        {performances.map((perf) => (
                          <li key={perf.id}>
                            <span>
                              <strong>{perf.time}</strong> - {perf.dj.map((d) => d.name).join(', ')}
                            </span>
                            {isSuperuser && (
                              <button className="delete-button" onClick={() => handleDeletePerformance(perf.id)} title="Delete Timeslot">
                                &times;
                              </button>
                            )}
                          </li>
                        ))}
                      </ul>
                      {isSuperuser && (
                        <button className="download-button" onClick={handleDownloadGraphics}>
                          Download Graphics
                        </button>
                      )}
                    </div>
                  )}
                  {isSuperuser && (
                    <>
                      <button onClick={() => setIsEditingEvent(true)}>Edit Event</button>
                      <button onClick={handleDeleteEvent}>Delete Event</button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="form-group">
                    <label>Event Name:</label>
                    <input
                      type="text"
                      value={eventName || eventDetails.event_name}
                      onChange={(e) => setEventName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Week Number:</label>
                    <input
                      type="number"
                      value={weekNo || eventDetails.week_no}
                      onChange={(e) => setWeekNo(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Program:</label>
                    <select value={program || eventDetails.program} onChange={(e) => setProgram(e.target.value)}>
                      {eventTypes.map((ev) => (
                        <option key={ev.value} value={ev.value}>{ev.label}</option>
                      ))}
                    </select>
                  </div>
                  {isSuperuser && (
                    <div className="form-group">
                      <label>Event Color:</label>
                      <input type="color" value={color || eventDetails.color} onChange={(e) => setColor(e.target.value)} />
                    </div>
                  )}
                  <div className="form-group">
                    <label>Event Background Image:</label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleEventBgFileChange}
                    />
                  </div>
                  {showCropper && (
                    <div className="cropper-modal">
                      <div className="cropper-wrapper">
                        <Cropper
                          image={imageSrc}
                          crop={crop}
                          zoom={zoom}
                          aspect={1}
                          onCropChange={setCrop}
                          onZoomChange={setZoom}
                          onCropComplete={onCropComplete}
                          cropShape="rect"
                        />
                        <div className="slider-container">
                          <Slider
                            min={1}
                            max={3}
                            step={0.1}
                            value={zoom}
                            onChange={(e, zoom) => setZoom(zoom)}
                          />
                        </div>
                        <div className="button-group">
                          <button onClick={showCroppedImage}>Crop</button>
                          <button onClick={() => {
                            setShowCropper(false);
                            setEventBgFile(null);
                            setImageSrc(null);
                          }}>Cancel</button>
                        </div>
                      </div>
                    </div>
                  )}
                  {croppedImage && (
                    <div className="cropped-image-preview">
                      <img src={URL.createObjectURL(croppedImage)} alt="Cropped" />
                    </div>
                  )}

                  <button onClick={handleUpdateEvent}>Save Changes</button>
                  <button onClick={() => setIsEditingEvent(false)}>Cancel</button>
                </>
              )}
            </div>
          ) : (
            <div className="new-event-section">
              <h3>Create or Edit Event</h3>
              <div className="form-group">
                <label>Event Name:</label>
                <input
                  type="text"
                  value={eventName}
                  onChange={(e) => setEventName(e.target.value)}
                  placeholder="Event Name"
                />
              </div>
              <div className="form-group">
                <label>Week Number:</label>
                <input
                  type="number"
                  value={weekNo}
                  onChange={(e) => setWeekNo(e.target.value)}
                  placeholder="Week No."
                />
              </div>
              <div className="form-group">
                <label>Program:</label>
                <select value={program} onChange={(e) => setProgram(e.target.value)}>
                  {eventTypes.map((ev) => (
                    <option key={ev.value} value={ev.value}>{ev.label}</option>
                  ))}
                </select>
              </div>
              {isSuperuser && (
                <div className="form-group">
                  <label>Event Color:</label>
                  <input type="color" value={color} onChange={(e) => setColor(e.target.value)} />
                </div>
              )}
              <div className="form-group">
                <label>Event Background Image:</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleEventBgFileChange}
                />
              </div>
              {showCropper && (
                <div className="cropper-modal">
                  <div className="cropper-wrapper">
                    <Cropper
                      image={imageSrc}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={onCropComplete}
                      cropShape="rect"
                    />
                    <div className="slider-container">
                      <Slider
                        min={1}
                        max={3}
                        step={0.1}
                        value={zoom}
                        onChange={(e, zoom) => setZoom(zoom)}
                      />
                    </div>
                    <div className="button-group">
                      <button onClick={showCroppedImage}>Crop</button>
                      <button onClick={() => {
                        setShowCropper(false);
                        setEventBgFile(null);
                        setImageSrc(null);
                      }}>Cancel</button>
                    </div>
                  </div>
                </div>
              )}
              {croppedImage && (
                <div className="cropped-image-preview">
                  <img src={URL.createObjectURL(croppedImage)} alt="Cropped" />
                </div>
              )}

              {/* Labels */}
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={showLabelFields}
                    onChange={(e) => setShowLabelFields(e.target.checked)}
                  />
                  Add Label
                </label>
              </div>
              {showLabelFields && (
                <div className="form-group">
                  <label>Labels:</label>
                  <select multiple value={selectedLabels} onChange={handleLabelChange}>
                    {labels.map((l) => (
                      <option key={l.id} value={l.id}>{l.label_name}</option>
                    ))}
                    <option value="new">Create New Label</option>
                  </select>
                  {creatingNewLabel && (
                    <div className="new-label-fields">
                      <input
                        type="text"
                        placeholder="Label Name"
                        onChange={(e) => setNewLabelData({ ...newLabelData, label_name: e.target.value })}
                      />
                      <textarea
                        placeholder="Label Description"
                        onChange={(e) => setNewLabelData({ ...newLabelData, label_desc: e.target.value })}
                      ></textarea>
                      <input
                        type="text"
                        placeholder="Nationality"
                        onChange={(e) => setNewLabelData({ ...newLabelData, nationality: e.target.value })}
                      />
                      <input type="file" accept="image/*" onChange={(e) => handleLogoFileChange(e, 'label')} />
                    </div>
                  )}
                </div>
              )}

              {/* Partners */}
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={showPartnerFields}
                    onChange={(e) => setShowPartnerFields(e.target.checked)}
                  />
                  Add Partner
                </label>
              </div>
              {showPartnerFields && (
                <div className="form-group">
                  <label>Partners:</label>
                  <select multiple value={selectedPartners} onChange={handlePartnerChange}>
                    {partners.map((p) => (
                      <option key={p.id} value={p.id}>{p.name}</option>
                    ))}
                    <option value="new">Create New Partner</option>
                  </select>
                  {creatingNewPartner && (
                    <div className="new-partner-fields">
                      <input
                        type="text"
                        placeholder="Partner Name"
                        onChange={(e) => setNewPartnerData({ ...newPartnerData, name: e.target.value })}
                      />
                      <textarea
                        placeholder="Partner Description"
                        onChange={(e) => setNewPartnerData({ ...newPartnerData, desc: e.target.value })}
                      ></textarea>
                      <input type="file" accept="image/*" onChange={(e) => handleLogoFileChange(e, 'partner')} />
                    </div>
                  )}
                </div>
              )}

              {/* Locations */}
              <div className="form-group">
                <label>Location:</label>
                <select multiple value={selectedLocations} onChange={handleLocationChange}>
                  {locations.map((loc) => (
                    <option key={loc.id} value={loc.id}>{loc.location_name}</option>
                  ))}
                  <option value="new">Create New Location</option>
                </select>
                {creatingNewLocation && (
                  <div className="new-location-fields">
                    <input
                      type="text"
                      placeholder="Location Name"
                      onChange={(e) => setNewLocationData({ ...newLocationData, location_name: e.target.value })}
                    />
                    <textarea
                      placeholder="Location Address"
                      onChange={(e) => setNewLocationData({ ...newLocationData, location_addr: e.target.value })}
                    ></textarea>
                    <input type="file" accept="image/*" onChange={(e) => handleLogoFileChange(e, 'location')} />
                  </div>
                )}
              </div>

              {/* Timeslots */}
              <div className="form-group">
                <label>Select Timeslots:</label>
                <div className="timeslots-buttons">
                  {timeOptions.map((time) => (
                    <button
                      key={time}
                      className={`timeslot-button ${selectedSlots.includes(time) ? 'selected' : ''}`}
                      onClick={() => toggleSlotSelection(time)}
                    >
                      {time}
                    </button>
                  ))}
                </div>
              </div>

              <button className="add-slots-button" onClick={handleAddSlots}>Add Slots</button>
            </div>
          )}
        </div>
      </div>

      {/* Logo Cropper Modal */}
      {showLogoCropper && (
        <div className="cropper-modal">
          <div className="cropper-wrapper">
            <Cropper
              image={logoImageSrc}
              crop={logoCrop}
              zoom={logoZoom}
              aspect={1}
              onCropChange={setLogoCrop}
              onZoomChange={setLogoZoom}
              onCropComplete={onLogoCropComplete}
              cropShape="rect"
            />
            <div className="slider-container">
              <Slider
                min={1}
                max={3}
                step={0.1}
                value={logoZoom}
                onChange={(e, zoom) => setLogoZoom(zoom)}
              />
            </div>
            <div className="button-group">
              <button onClick={showCroppedLogoImage}>Crop</button>
              <button onClick={() => {
                setShowLogoCropper(false);
                setLogoImageSrc(null);
                setCurrentLogoField('');
              }}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* Cropped Logo Previews */}
      {croppedLabelLogo && <img src={URL.createObjectURL(croppedLabelLogo)} alt="Cropped Label" style={{maxWidth: "100px"}} />}
      {croppedPartnerLogo && <img src={URL.createObjectURL(croppedPartnerLogo)} alt="Cropped Partner" style={{maxWidth: "100px"}} />}
      {croppedLocationLogo && <img src={URL.createObjectURL(croppedLocationLogo)} alt="Cropped Location" style={{maxWidth: "100px"}} />}
    </div>
  );
};

export default AvailableSlotsAdmin;
