import React, { useContext, useState, useRef, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './TopMenu.css';
import { toast } from 'react-toastify';
import './loading-overlay.css';

const TopMenu = () => {
    const {
        isAuthenticated,
        userRoles,
        username,
        hasDjProfile,
        logout,
    } = useContext(AuthContext);

    const [tickerText, setTickerText] = useState('');
    const [loadingTicker, setLoadingTicker] = useState(true);
    const [errorTicker, setErrorTicker] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const dropdownRef = useRef(null);
    

    useEffect(() => {
        const fetchTickerText = async () => {
            try {
                const response = await axios.get('/api/next_week_programs/');
                setTickerText(response.data.tickerText);
            } catch (err) {
                console.error('Error fetching ticker text:', err);
                setErrorTicker(true);
            } finally {
                setLoadingTicker(false);
            }
        };
        fetchTickerText();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');
        } catch (err) {
            console.error('Error logging out:', err);
            alert('Error logging out. Please try again.');
        }
    };

    const handleApplyClick = (e) => {
        if (!isAuthenticated) {
            e.preventDefault();
            toast.info('Please login to apply to the radio programs!');
            navigate('/login');
        }
    };

    const isDj = userRoles.includes('dj');
    const isContentManager = userRoles.includes('content_manager');
    const isOperationAdmin = userRoles.includes('operation_admin');
    const isSuperuser = userRoles.includes('superuser');
    const isUserAdmin = userRoles.includes('user_admin'); // Check for user_admin role
    const isMaterialAdmin = userRoles.includes('material_admin');

    return (
        <div className="top-menu">
            {loadingTicker && (
              <div className="loading-overlay">
                <img src="static/img/loading.png" alt="Loading" className="loading-gif" />
              </div>
            )}

            {/* Logo on the left */}
            <div className="logo">
                <Link to="/">
                    <img src="/static/logo/byyb.png" alt="Logo" />
                </Link>
            </div>

            {/* Ticker in the center */}
            <div className="ticker">
                {loadingTicker && <div className="ticker-loading">Loading programs...</div>}
                {errorTicker && <div className="ticker-error">Error loading programs.</div>}
                {!loadingTicker && !errorTicker && <div className="ticker-text">{tickerText}</div>}
            </div>

            {/* Dropdown Menu */}
            <div className="menu-dropdown" ref={dropdownRef}>
                <button
                    className="dropdown-button"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    aria-haspopup="true"
                    aria-expanded={dropdownOpen}
                >
                    {isAuthenticated ? `Hello, ${username}` : '☰ Menu'}
                </button>
                {dropdownOpen && (
                    <div className="dropdown-content">
                    {!isAuthenticated && (
                    <>
                        <Link to="/apply" className="dropdown-item" onClick={(e) => { handleApplyClick(e); setDropdownOpen(false); }}>
                        Apply!
                        </Link>
                        <Link to="/login" className="dropdown-item" onClick={() => setDropdownOpen(false)}>Login</Link>
                        <Link to="/signup" className="dropdown-item" onClick={() => setDropdownOpen(false)}>Sign Up</Link>
                    </>
                    )}

                    {isAuthenticated && isDj && (
                        <>
                            <Link to="/dj/profile" className="dropdown-item" onClick={() => setDropdownOpen(false)}>Artist Profile</Link>
                            <Link to="/booking" className="dropdown-item" onClick={() => setDropdownOpen(false)}>Bookings</Link>
                        </>
                    )}

                    {isAuthenticated && (
                        <Link to="/dj/account_settings" className="dropdown-item" onClick={() => setDropdownOpen(false)}>
                            Account Settings
                        </Link>
                    )}

                    {(isAuthenticated && (isContentManager || isSuperuser)) && (
                        <Link to="/content_manager" className="dropdown-item" onClick={() => setDropdownOpen(false)}>Content Manager</Link>
                    )}

                    {(isAuthenticated && (isSuperuser || isUserAdmin)) && (
                        <Link to="/admin/users" className="dropdown-item" onClick={() => setDropdownOpen(false)}>User Admin</Link>
                    )}

                    {(isAuthenticated && (isOperationAdmin || isSuperuser)) && (
                        <Link to="/admin/operations" className="dropdown-item" onClick={() => setDropdownOpen(false)}>
                            Operation Admin
                        </Link>
                    )}
                    {(isAuthenticated && (isMaterialAdmin || isSuperuser)) && (
                        <Link to="/material_admin" className="dropdown-item" onClick={() => setDropdownOpen(false)}>
                            Material Admin
                        </Link>
                    )}

                    {isAuthenticated && isSuperuser && (
                        <Link to="/admin/available-slots" className="dropdown-item" onClick={() => setDropdownOpen(false)}>Manage Slots</Link>
                    )}

                    <Link to="/contact" className="dropdown-item" onClick={() => setDropdownOpen(false)}>Contact Us</Link>

                    {isAuthenticated && (
                        <button onClick={() => { handleLogout(); setDropdownOpen(false); }} className="dropdown-item">
                            Logout
                        </button>
                    )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TopMenu;
