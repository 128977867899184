// src/ContactUs.js
import React from 'react';

const ContactUs = () => {
  return (

    <div style={{ maxWidth: '600px', margin: '80px auto 20px', padding: '20px', backgroundColor: '#fff', border: '1px solid #000' }}>
      <h2 style={{ textAlign: 'center' }}>Contact Us</h2>
      <p style={{ fontSize: '0.9rem', lineHeight: '1.5', padding: '10px 0' }}>
        If you have questions, collaboration opportunities, application questions, or need more information about our radio programs, 
        don't hesitate to reach out! 
        
        We are passionate about connecting with our community 
        the culture and good music 🪴
        
      </p>
      <p style={{ fontSize: '0.9rem', lineHeight: '1.5' }}>
        Feel free to email us at: <strong>byyb.radio@gmail.com</strong>
      </p>
      <p style={{ fontSize: '0.9rem', lineHeight: '1.5' }}>
      We are a community radio located in the heart of Shanghai, China 
      </p>
      <p style={{ fontSize: '0.9rem', lineHeight: '1.5' }}>
      Host with 💚
      </p>
      <p>-</p>
      <ul style={{ fontSize: '0.8rem', lineHeight: '1.5', listStyleType: 'none', padding: 0}}>
        <li><a href="https://www.instagram.com/byyb.radio" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>instagram: byyb.radio</a></li>
        <li><a href="https://www.youtube.com/@byyb_radio" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}  >youtube: byyb_radio</a></li>
        <li><a href="https://weixin.qq.com/r/byyb" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>wechat: byyb</a></li>
        <li><a href="https://www.xiaohongshu.com/user/profile/63565b35000000001901c0dd" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>xiaohongshu: byyb</a></li>
        <li><a href="https://space.bilibili.com/330497744" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>bilibili: byyb_radio</a></li>
      </ul>
    </div>
  );
};

export default ContactUs;
