import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './ContentEventDetail.css'; // Ensure this CSS file is in the same directory

const ContentEventDetail = () => {
    const { eventId } = useParams();
    const [performances, setPerformances] = useState([]);
    const [eventDetails, setEventDetails] = useState(null);
    const [showExtraInfo, setShowExtraInfo] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        fetchEventDetails();
        fetchPerformances();
    }, []);

    const fetchEventDetails = async () => {
        try {
            const response = await axios.get(`/api/content_manager/events/${eventId}/`);
            setEventDetails(response.data);
        } catch (error) {
            console.error('Error fetching event details:', error);
        }
    };

    const fetchPerformances = async () => {
        try {
            const response = await axios.get(`/api/content_manager/events/${eventId}/performances/`);
            setPerformances(response.data);
        } catch (error) {
            console.error('Error fetching performances:', error);
        }
    };

    const handleToggleExtraInfo = (id) => {
        setShowExtraInfo(prev => ({ ...prev, [id]: !prev[id] }));
    };

    return (
        <div className="content-event-detail-container">
            {eventDetails && (
                <div className="detail-header">
                    <span className="back-arrow" onClick={() => navigate('/content_manager')}>&larr;</span>
                    <h2>{eventDetails.date} - {eventDetails.event_name}</h2>
                </div>
            )}
            <div className="performances-list">
                {performances.map((performance) => (
                    <div className="performance-box" key={performance.id}>
                        <div className="performance-thumbnail">
                            {performance.square_thumbnail_url ? (
                                <img src={performance.square_thumbnail_url} alt="Thumbnail" />
                            ) : (
                                <div className="thumbnail-placeholder">No Thumbnail</div>
                            )}
                        </div>
                        <div className="performance-info">

                            <p><strong>Event:</strong> {performance.performance_full_name}</p>
                            <p><strong>Time:</strong> {performance.time}</p>
                            <p><strong>DJ(s):</strong> {performance.dj.map(dj => dj.name).join(', ')}</p>
                            {performance.yt_link && (
                                <p><strong>YouTube:</strong> <a href={performance.yt_link} target="_blank" rel="noopener noreferrer">{performance.yt_link}</a></p>
                            )}
                            <p><strong>Publish Date:</strong> {performance.publish_date}</p>
                            {performance.audio_file_url && (
                                <p><strong>Audio:</strong> <a href={performance.audio_file_url} download>Download Audio</a></p>
                            )}
                            
                            <button
                                className="toggle-extra-info-btn"
                                onClick={() => handleToggleExtraInfo(performance.id)}
                            >
                                {showExtraInfo[performance.id] ? 'Hide' : 'Show'} Title & Description
                            </button>
                            
                            {showExtraInfo[performance.id] && (
                                <div className="extra-info">
                                    <div className="video-thumbnail">
                                        {performance.video_thumbnail_touched ? (
                                            <img src={performance.video_thumbnail_touched} alt="Video Thumbnail" />
                                        ) : (
                                            <div className="thumbnail-placeholder">No Thumbnail</div>
                                        )}
                                    </div>
                                    <p><strong>Bilibili Title:</strong></p>
                                    <p className="preserve-whitespace">{performance.bili_title}</p>
                                    <br></br>
                                    <p><strong>Bilibili Description:</strong></p>
                                    <p className="preserve-whitespace">{performance.bili_desc}</p>
                                    <hr></hr>
                                    <p><strong>SoundCloud Title:</strong></p>
                                    <p className="preserve-whitespace">{performance.sc_title}</p>
                                    <br></br>
                                    <p><strong>SoundCloud Description:</strong></p>
                                    <p className="preserve-whitespace">{performance.sc_desc}</p>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ContentEventDetail;
