// src/Booking.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Booking.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { enUS } from 'date-fns/locale';
import {
  format,
  isBefore,
  isAfter,
  addDays,
  parseISO,
  differenceInDays,
} from 'date-fns';
import { AuthContext } from './AuthContext'; // Import AuthContext
import { useNavigate, useLocation } from 'react-router-dom';

registerLocale('en-US', enUS);

const Booking = () => {
  const { username, hasDjProfile, loading } = useContext(AuthContext);; // Access current user's username
  const navigate = useNavigate(); 

  const [userBookings, setUserBookings] = useState([]);
  const [loadingBookings, setLoadingBookings] = useState(true);

  const [availableDates, setAvailableDates] = useState([]);
  const [userBookedDates, setUserBookedDates] = useState([]); // Store dates where the user has bookings
  const [selectedDate, setSelectedDate] = useState(null);
  const [defaultDate, setDefaultDate] = useState(new Date()); // Default date for the calendar
  const [performances, setPerformances] = useState([]);
  const [selectedPerformance, setSelectedPerformance] = useState(null);
  const [isBooking, setIsBooking] = useState(false);
  const [loadingDates, setLoadingDates] = useState(true);
  const [loadingTimeslots, setLoadingTimeslots] = useState(false);
  const [eventDetails, setEventDetails] = useState(null); // Store event details
  const [canBook, setCanBook] = useState(true); // New state for booking eligibility
  const [bookingMessage, setBookingMessage] = useState(''); // Message explaining why booking is disabled

  const [isLive, setIsLive] = useState(null);
  const [mixerModel, setMixerModel] = useState('DJM-900 NXS2');

  const [step, setStep] = useState(1); // Step state

  // Loading states for navigation buttons
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isPreviousLoading, setIsPreviousLoading] = useState(false);

  // Language state
  const [language, setLanguage] = useState('zh'); // 'zh' for Chinese, 'en' for English

  // Loading overlay state
  const [isLoading, setIsLoading] = useState(false);

  // Added state to control the display of the booking process
  const [showBookingProcess, setShowBookingProcess] = useState(false);

  // State for upcoming booking reminder
  const [upcomingBooking, setUpcomingBooking] = useState(null);
  const [daysLeft, setDaysLeft] = useState(null);
  
  // Add new state for DJ profile
  const [djProfile, setDjProfile] = useState(null);

  const location = useLocation();

  // Add useEffect to fetch DJ profile when component mounts
  useEffect(() => {
    const fetchDjProfile = async () => {
      try {
        const response = await axios.get('/api/dj/profile/');
        setDjProfile(response.data);
      } catch (error) {
        console.error('Error fetching DJ profile:', error);
      }
    };

    if (hasDjProfile) {
      fetchDjProfile();
    }
  }, [hasDjProfile]);

  useEffect(() => {
    if (location.state && location.state.startBooking) {
      setShowBookingProcess(true);
    }
  }, [location.state]);

  const programs = {
    ia: {
      name: 'ivy arum session 🪴',
      intro:
        "ivy arum session (绿萝时间) is a program that originated from the byyb.radio station, focusing on non-club music only. Like the resilient green ivy, the ivy arum session is committed to bringing great music from people's headphones to various live and radio settings. by meticulously refined and mixed by Djs and Live performers, ivy arum session creates and delivers unprecedented listening experiences for both the performer and the audience."
    },
    rs: {
      name: 'byyb.radio Main Program 💚',
      intro:
        "Since October 2023, the main program has been running for nearly 40 weeks, featuring over 400 outstanding DJ & musicians. From the roadside of Jinxian Road to the entrance of a shopping mall on Huaihai Road, the program has been moving in sync with the city of Shanghai and creating countless beautiful moments with its guests. Held weekly at the radio station, rain or shine, the radio program has become a haven for music lovers, a communication hub for friends worldwide, and an organic source for spreading aesthetics and genuine music."
    },
    hr: {
      name: 'Heimliday Radio 🌳',
      intro:
        "The Heimliday Radio program, jointly hosted by Heim club and byyb.radio in Shanghai, China, officially launched in March 2024. Heim's healing atmosphere combined with byyb's openness has filled the entire courtyard with music and love. In the past six episodes, we have witnessed many fantastic performances and label showcases, including Genome 6.66 Mbp, http. Shanghai, Chilldo (池塘), and others."
    },
    bo: {
      name: 'byyb.radio Out 💨',
      intro:
        "Whether it's at the entrance of a street alley, a busy mall, the heart of a club, a sports field, along the bustling shores of the Huangpu River, in a bar's courtyard, or even in front of a restaurant's cashier counter, byyb.radio's events can be found. byyb.radio seems to have become synonymous with \"good music on the move\": wherever it goes, byyb.radio is committed to delivering the best music, curated live by talented DJs and musicians, and spreading it to every corner of the city and the world. byyb.radio has shared its creativity with partners like VANS, ASICS, SYSTEM (club), HEADACHE (头痛集团), Pie Society (派社), Common Rare (凡几市集), Savvy Market etc..."
    },
  };

  // Translations object
  const translations = {
    zh: {
      title: 'byyb.radio 预约电台 (Book a Session)',
      loadingBookings: '加载您的预约...',
      yourBookings: '您的预约',
      bookNewSession: '预约新的时段',
      backToBooking: '返回你的预约',
      reminder: {
        greeting: '你好 {name}，',
        message:
          '距离你下次电台做客还有 {days} 天，这是一些信息：电台做客时间，请提前至少10分钟来我们可以试音。如有紧急情况，需取消预约，请联系我们: byyb.radio@gmail.com',
      },
      daysLeft: '{days} 天后',
      step1: {
        header: '关于预约',
        content:
          '欢迎预约 byyb.radio, 我们致力于打造一个高质量的平台能连接和帮助DJ和音乐人链接，创作内容和全面展示的平台。',
        next: '下一步',
      },
      step2: {
        header: '电台做客注意事项',
        content: [
          '1. 为保证有空位留给其他未做客过的嘉宾，我们会保证同一位嘉宾在三个月内只能预约一次电台活动。',
          '2. 预约后可在活动两周之前随时取消；如需在活动两周以内需要临时取消预约，请直接联系电台: byyb.radio@gmail.com。',
          '3. 活动录制素材将在活动后10天内交付，同时会排期上传byyb官方 Youtube, Soundcloud, Bilibili, 如有特殊需求，请及时联系我们，做客嘉宾在拿到素材之后可选择是否上传平台。',
          '4. byyb.radio 的社交媒体会将嘉宾做客时拍摄的素材和之后的录制录制素材选择性发布 byyb 社交媒体。',
          '5. 电台地址：上海市长乐路115号。不是TX淮海，也不是进贤路！',
          '6. 如遇紧急情况或极端天气，电台将提前通知。',
          '7. 请至少提前10分钟到达电台，以便准备和试音。',
          '8. 整个演出时长请控制在一小时以内。',
          '9. 有疑问请联系: byyb.radio@gmail.com',
        ],
        previous: '上一步',
        next: '下一步',
      },
      step3: {
        header: '选择日期',
        timeslotHeader: '可用时间段：{date}',
        previous: '上一步',
        next: '下一步',
      },
      step4: {
        header: '选择演出类型',
        liveSet: 'Live Set',
        djSet: 'DJ Set',
        mixerModelLabel: '首选混音台型号：',
        previous: '上一步',
        next: '下一步',
        errorSelectPerformanceType: '请选择您的演出类型。',
      },
      step5: {
        header: '确认预订详情',
        date: '日期',
        time: '时间',
        performanceType: '演出类型',
        mixerModel: '混音台型号',
        liveSet: 'Live Set',
        djSet: 'DJ Set',
        previous: '上一步',
        confirm: '确认预订 🪴',
        djName: '嘉宾',
      },
      loadingDates: '加载可用日期...',
      loadingTimeslots: '加载时间段...',
      noTimeslots: '该日期暂无可用时间段。',
      translate: 'EN',
      booking: {
        bookingError: '请先选择一个时间段进行预约。',
        bookingSuccess: '预约已确认！',
        bookingLimit: '您已达到预约限制。',
        bookingErrorMessage:
          '您无法预约新的演出，因为您在最近3个月内有过预约。',
        bookingGenericError: '预约时出错。',
      },
    },
    en: {
      title: 'byyb.radio Booking (Book a Session)',
      loadingBookings: 'Loading your bookings...',
      yourBookings: 'Your Bookings',
      bookNewSession: 'Book a New Session',
      backToBooking: 'Back to Your Bookings',
      reminder: {
        greeting: 'Hello {name},',
        message:
          'There are {days} days left until your next radio session. Here is some information: Please arrive at least 10 minutes early for sound check. If you need to cancel your booking due to an emergency, please contact us.',
      },
      daysLeft: '{days} days left',
      step1: {
        header: 'About Booking',
        content:
          'We will display all upcoming radio activities. If there is a radio event on that day, you can book a slot at any time! In addition to the fixed weekly main program, the radio will also host special radio events in different settings, all of which can be booked! Please stay tuned!',
        next: 'Next',
      },
      step2: {
        header: 'Notice',
        content: [
          '1. If you have made a radio booking within the last three months, we will give priority to other guests for available slots.',
          '2. After booking, you can cancel anytime two weeks before the event; if you need to cancel urgently within two weeks, please contact the radio directly.',
          '3. Event recording materials will be delivered within 10 days after the event and will be scheduled for upload to byyb official YouTube, Soundcloud, Bilibili. If you have any special requests, please contact us promptly.',
          '4. byyb.radio\'s social media will selectively publish highlights of the guests.',
          '5. Radio address: 115 Changle Road, Shanghai.',
          '6. In case of emergencies or extreme weather, the radio will notify you in advance.',
          '7. Please arrive at the radio at least 10 minutes in advance for preparation and sound check.',
          '8. Please keep the entire performance duration within one hour.',
          '9. If you have any questions, please contact: byyb.radio@gmail.com',
        ],
        previous: 'Previous',
        next: 'Next',
      },
      step3: {
        header: 'Choose Date',
        timeslotHeader: 'Available Timeslots for {date}',
        previous: 'Previous',
        next: 'Next',
      },
      step4: {
        header: 'Select Performance Type',
        liveSet: 'Live Set',
        djSet: 'DJ Set',
        mixerModelLabel: 'Preferred Mixer Model:',
        previous: 'Previous',
        next: 'Next',
        errorSelectPerformanceType: 'Please select your performance type.',
      },
      step5: {
        header: 'Confirm Booking Details',
        date: 'Date',
        time: 'Time',
        performanceType: 'Performance Type',
        mixerModel: 'Mixer Model',
        liveSet: 'Live Set',
        djSet: 'DJ Set',
        previous: 'Previous',
        confirm: 'Confirm Booking 🪴',
        djName: 'Guest',
      },
      loadingDates: 'Loading available dates...',
      loadingTimeslots: 'Loading timeslots...',
      noTimeslots: 'No timeslots available for this date.',
      translate: '中',
      booking: {
        bookingError: 'Please select a timeslot to book.',
        bookingSuccess: 'Booking confirmed!',
        bookingLimit: 'You have reached your booking limit.',
        bookingErrorMessage:
          'You cannot book a new performance because you have a recent booking within the last 3 months.',
        bookingGenericError: 'Error booking performance.',
      },
    },
  };

  useEffect(() => {
    if (loading) {
      return; // Wait until AuthContext has finished loading
    }
    if (!hasDjProfile) {
      navigate('/dj/profile/', { state: { fromBooking: true } });
    }
  }, [hasDjProfile, navigate, loading]);


  // Fetch user bookings
  useEffect(() => {
    if (!showBookingProcess && hasDjProfile) {
      const fetchUserBookings = async () => {
        setLoadingBookings(true);
        try {
          const response = await axios.get('/api/performances/my_bookings/');
          setUserBookings(response.data);

          // Check for upcoming bookings within 10 days
          const today = new Date();
          const tenDaysLater = addDays(today, 10);

          const upcoming = response.data
            .map((booking) => {
              const eventDate = parseISO(booking.event_date);
              const daysLeft = differenceInDays(eventDate, today);
              return { ...booking, eventDate, daysLeft };
            })
            .filter(
              (booking) =>
                isAfter(booking.eventDate, today) &&
                isBefore(booking.eventDate, tenDaysLater)
            )
            .sort((a, b) => a.eventDate - b.eventDate);

          if (upcoming.length > 0) {
            setUpcomingBooking(upcoming[0]);
            setDaysLeft(upcoming[0].daysLeft);
          } else {
            setUpcomingBooking(null);
            setDaysLeft(null);
          }
        } catch (error) {
          console.error('Error fetching user bookings:', error);
          toast.error('Error fetching your bookings.');
        } finally {
          setLoadingBookings(false);
        }
      };

      // Fetch bookings when not in booking process
      if (!showBookingProcess) {
        fetchUserBookings();
      }
    }
  }, [showBookingProcess, hasDjProfile]);

  // Fetch available dates, user booked dates, and booking eligibility
  useEffect(() => {
    const fetchAvailableDates = async () => {
      setLoadingDates(true);
      try {
        const response = await axios.get('/api/performances/available_dates/');
        setAvailableDates(response.data.available_dates);
        setUserBookedDates(response.data.user_booked_dates);
      } catch (error) {
        console.error('Error fetching available dates:', error);
        toast.error('Error fetching available dates.');
      } finally {
        setLoadingDates(false);
      }
    };
  
    if (showBookingProcess) {
      fetchAvailableDates();
    }
  }, [showBookingProcess]);
  
  
  useEffect(() => {
    const checkBookingEligibility = async () => {
      if (!selectedPerformance) {
        setCanBook(true); // Assume can book if no performance is selected
        setBookingMessage('');
        return;
      }
  
      try {
        const response = await axios.get('/api/performances/can_book/', {
          params: {
            date: format(selectedDate, 'yyyy-MM-dd'),
            event_id: selectedPerformance.event_id,
          },
        });
  
        setCanBook(response.data.can_book);
        setBookingMessage(response.data.message || '');
      } catch (error) {
        console.error('Error checking booking eligibility:', error);
        console.log('Selected Performance:', selectedPerformance);
        console.log('Selected Date:', selectedDate);
        setCanBook(false);
        setBookingMessage('Error checking booking eligibility.');
      }
    };
  
    if (showBookingProcess && selectedPerformance) {
      checkBookingEligibility();
    }
  }, [selectedPerformance, selectedDate, showBookingProcess]);

  // Fetch timeslots for the selected date
  useEffect(() => {
    const fetchTimeslots = async () => {
      if (!selectedDate) return;
      setLoadingTimeslots(true);
      try {
        const formattedDate = format(selectedDate, 'yyyy-MM-dd');
        const response = await axios.get('/api/performances/timeslots/', {
          params: { date: formattedDate },
        });
        setPerformances(response.data);
  
        if (response.data.length > 0) {
          const firstPerformance = response.data[0];
          const eventData = {
            week_no: firstPerformance.event_week_no,
            event_name: firstPerformance.event_name,
            date: firstPerformance.event_date,
            duration_info: firstPerformance.duration_info,
            program: firstPerformance.event_program,
            location: firstPerformance.location,
            partner: firstPerformance.partner,
            label: firstPerformance.label,
          };
          setEventDetails(eventData);
        } else {
          setEventDetails(null);
        }
      } catch (error) {
        console.error('Error fetching timeslots:', error);
        toast.error('Error fetching timeslots.');
      } finally {
        setLoadingTimeslots(false);
      }
    };
  
    if (selectedDate) {
      fetchTimeslots();
    }
  }, [selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedPerformance(null);
    
    // After setting the date, scroll to bottom
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      });
    }, 100); // Small delay to ensure content is rendered
  };

  const handlePerformanceSelection = (performance) => {
    const isBooked = performance.dj.length > 0;
    const isBookedByUser = performance.dj.some((dj) => dj.username === username);

    if (isBooked && !isBookedByUser) {
      // Timeslot is already booked by someone else
      return;
    }
    setSelectedPerformance(performance);
  };

  const handleBookPerformance = async () => {
    if (!selectedPerformance) {
      toast.error(
        language === 'zh'
          ? translations.zh.booking.bookingError
          : translations.en.booking.bookingError
      );
      return;
    }

    if (!canBook) {
      toast.error(
        language === 'zh'
          ? translations.zh.booking.bookingErrorMessage
          : translations.en.booking.bookingErrorMessage
      );
      return;
    }

    setIsLoading(true);
    setIsBooking(true);
    try {
      const data = {
        event_id: selectedPerformance.event_id,
        time: selectedPerformance.time,
        is_live: isLive,
      };
  
      if (isLive === false) {
        data.mixer_model = mixerModel;
      }
  
      const response = await axios.post('/api/performances/book/', data);
      const updatedPerformance = response.data;

      // Update the performances list with the updated performance
      setPerformances((prevPerformances) =>
        prevPerformances.map((p) => (p.id === updatedPerformance.id ? updatedPerformance : p))
      );


      toast.success(
        language === 'zh'
          ? translations.zh.booking.bookingSuccess
          : translations.en.booking.bookingSuccess
      );

      setSelectedPerformance(null);
			setSelectedPerformance(null);
			setIsLive(null);
			setMixerModel('');
			setStep(1);

      // After booking, check if the user can book again
      const eligibilityResponse = await axios.get('/api/performances/can_book/', {
        params: {
          date: format(selectedDate, 'yyyy-MM-dd'),
          event_id: selectedPerformance.event_id,
        },
      });
      const { can_book, message } = eligibilityResponse.data;
      setCanBook(can_book);
      setBookingMessage(message || '');

      if (!can_book) {
        toast.info(
          language === 'zh'
            ? translations.zh.booking.bookingLimit
            : translations.en.booking.bookingLimit
        );
      }

      // Return to bookings list after booking
      setShowBookingProcess(false);
      setStep(1);
    } catch (error) {
      console.error('Error booking performance:', error);
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.detail ||
          Object.values(error.response.data)[0]?.[0] ||
          (language === 'zh'
            ? translations.zh.booking.bookingGenericError
            : translations.en.booking.bookingGenericError);
        toast.error(`Error: ${errorMessage}`);
      } else {
        toast.error(
          language === 'zh'
            ? translations.zh.booking.bookingGenericError
            : translations.en.booking.bookingGenericError
        );
      }
    } finally {
      setIsLoading(false);
      setIsBooking(false);
    }
  };

  const handleCancelBooking = async (performanceId) => {
    if (!window.confirm('Are you sure you want to cancel this booking?')) {
      return;
    }

    setIsLoading(true);
    try {
      // Make the cancel booking request
      await axios.post('/api/performances/cancel_booking/', {
        performance_id: performanceId,
      });
      
      toast.success('Booking canceled successfully.');
      
      // Update the local state
      const updatedBookings = userBookings.filter((booking) => booking.id !== performanceId);
      setUserBookings(updatedBookings);

      // Recalculate upcoming booking
      const today = new Date();
      const tenDaysLater = addDays(today, 10);

      const upcoming = updatedBookings
        .map((booking) => {
          const eventDate = parseISO(booking.event_date);
          const daysLeft = differenceInDays(eventDate, today);
          return { ...booking, eventDate, daysLeft };
        })
        .filter(
          (booking) =>
            isAfter(booking.eventDate, today) &&
            isBefore(booking.eventDate, tenDaysLater)
        )
        .sort((a, b) => a.eventDate - b.eventDate);

      if (upcoming.length > 0) {
        setUpcomingBooking(upcoming[0]);
        setDaysLeft(upcoming[0].daysLeft);
      } else {
        setUpcomingBooking(null);
        setDaysLeft(null);
      }
    } catch (error) {
      console.error('Error canceling booking:', error);
      toast.error('Error canceling booking.');
    } finally {
      setIsLoading(false);
    }
  };

  // Function to check if a date is disabled (past dates)
  const isDateDisabled = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return isBefore(date, today);
  };

  // Day class name function for DatePicker
  const dayClassName = (date) => {
    const dateStr = format(date, 'yyyy-MM-dd');
    if (isDateDisabled(date)) {
      return 'react-datepicker__day--disabled';
    } else if (userBookedDates.includes(dateStr)) {
      return 'react-datepicker__day--user-booked';
    } else if (availableDates.includes(dateStr)) {
      return 'react-datepicker__day--available';
    } else {
      return 'react-datepicker__day--unavailable';
    }
  };

  const handleNext = () => {
    if (step === 4 && isLive === null) {
      toast.error(
        language === 'zh'
          ? translations.zh.step4.errorSelectPerformanceType
          : translations.en.step4.errorSelectPerformanceType
      );
      return;
    }
  
    // Proceed to the next step
    if (step < steps.length + 1) {
      setIsNextLoading(true);
      setIsLoading(true);
      setTimeout(() => {
        setStep((prev) => prev + 1);
        setIsNextLoading(false);
        setIsLoading(false);
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
      }, 500);
    }
  };

  const handlePrevious = () => {
    if (step > 1) {
      setIsPreviousLoading(true);
      setIsLoading(true);
      setTimeout(() => {
        setStep((prev) => prev - 1);
        setIsPreviousLoading(false);
        setIsLoading(false);
      }, 500); // 0.5 seconds loading effect
    }
  };

  const toggleLanguage = () => {
    setLanguage((prev) => (prev === 'zh' ? 'en' : 'zh'));
  };

  const steps = [
    translations[language].step1.header,
    translations[language].step2.header,
    translations[language].step3.header,
    translations[language].step4.header,
  ];

  return (
    <div className="booking-container">
      {/* Translate Button */}
      <button className="translate-button" onClick={toggleLanguage}>
        {translations[language].translate}
      </button>

      {/* Blurred Content */}
      <div className={`content ${isLoading ? 'blur' : ''}`}>
        {/* <h2>{translations[language].title}</h2> */}

        {/* Reminder Message */}
        {upcomingBooking && (
          <div className="reminder-message">
            <p>
              {translations[language].reminder.greeting.replace('{name}', username)}
            </p>
            <p>
              {translations[language].reminder.message.replace('{days}', daysLeft)}
            </p>
          </div>
        )}

        {/* User Bookings Section */}
        {!showBookingProcess && (
          <>
            {loadingBookings ? (
              <p>{translations[language].loadingBookings}</p>
            ) : (
              <div className="user-bookings">
                <h2>{translations[language].yourBookings}</h2>
                <button
                  className="book-new-session-button"
                  onClick={() => setShowBookingProcess(true)}
                >
                  {translations[language].bookNewSession}
                </button>
                {userBookings.length === 0 ? (
                  <p>{translations[language].noBookings}</p>
                ) : (
                  <div className="bookings-list">
                    {userBookings.map((booking) => {
                      const eventDate = parseISO(booking.event_date);
                      const today = new Date();
                      const tenDaysLater = addDays(today, 10);

                      const isFutureBooking = isAfter(eventDate, today);
                      const canCancel = isFutureBooking && isAfter(eventDate, tenDaysLater);

                      return (
                        <div key={booking.id} className="booking-item">
                          {/* Display booking info */}
                          {booking.square_thumbnail_url && (
                            <img
                              src={booking.square_thumbnail_url}
                              alt="Thumbnail"
                              className="booking-thumbnail"
                            />
                          )}
                          <div className="booking-details">
                            <ul>
                              <li>
                                <strong>Date:</strong> {format(eventDate, 'yyyy-MM-dd')}
                              </li>
                              <li>
                                <strong>Time:</strong> {booking.time}
                              </li>
                              <li>
                                <strong>Is Live:</strong> {booking.is_live ? 'Yes' : 'No'}
                              </li>
                            </ul>

                            <ul>
                            {booking.publish_date && (
                              <li>
                                <strong>Publish Date:</strong> {booking.publish_date}
                              </li>
                            )}
                            {booking.yt_link && (
                              <li>
                                <strong>YouTube Link:</strong>{' '}
                                <a
                                  href={booking.yt_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Watch on YouTube
                                </a>
                              </li>
                            )}
                            {(booking.genre_1 || booking.genre_2 || booking.genre_3) && (
                              <li>
                                <strong>Genres:</strong>{' '}
                                {[booking.genre_1, booking.genre_2, booking.genre_3]
                                  .filter(Boolean)
                                  .join(', ')}
                              </li>
                            )}
                            </ul>
                          </div>
                          {/* For future bookings that can be canceled */}
                          {canCancel && (
                            <button
                              onClick={() => handleCancelBooking(booking.id)}
                              className="cancel-booking-button"
                            >
                              Cancel Booking
                            </button>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </>
        )}

        {/* Book a New Session */}
        {showBookingProcess && (
          <>
            {/* Cancel Booking Process Button */}
            <button
              className="cancel-booking-process-button"
              onClick={() => {
                setShowBookingProcess(false);
                setStep(1);
              }}
            >
              {translations[language].backToBooking}
            </button>

            {/* Progress Indicator */}
            <div className="progress-indicator">
              {steps.map((label, index) => (
                <div
                  key={index}
                  className={`step ${
                    step === index + 1 ? 'active' : step > index + 1 ? 'completed' : ''
                  }`}
                >
                  <div className="step-number">
                    {step > index + 1 ? '✔' : index + 1}
                  </div>
                  <div className="step-label">{label}</div>
                </div>
              ))}
            </div>

            {/* Step 1: 关于预约 / About Booking */}
            {step === 1 && (
              <>
                <h4>{translations[language].step1.header}</h4>
                <p>{translations[language].step1.content}</p>
                <div className="navigation-buttons">
                  <button
                    className="next-button"
                    onClick={handleNext}
                    disabled={isNextLoading}
                  >
                    {isNextLoading ? (
                      <span className="spinner"></span>
                    ) : (
                      translations[language].step1.next
                    )}
                  </button>
                </div>
              </>
            )}

            {/* Step 2: 注意事项 / Notice */}
            {step === 2 && (
              <>
                <h4>{translations[language].step2.header}</h4>
                <ul class="booking-description">
                  {translations[language].step2.content.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
                <div className="navigation-buttons">
                  <button
                    className="previous-button"
                    onClick={handlePrevious}
                    disabled={isPreviousLoading}
                  >
                    {isPreviousLoading ? (
                      <span className="spinner"></span>
                    ) : (
                      translations[language].step2.previous
                    )}
                  </button>
                  <button
                    className="next-button"
                    onClick={handleNext}
                    disabled={isNextLoading}
                  >
                    {isNextLoading ? (
                      <span className="spinner"></span>
                    ) : (
                      translations[language].step2.next
                    )}
                  </button>
                </div>
              </>
            )}

            {/* Step 3: Choose Date and Timeslot */}
            {step === 3 && (
              <>
                {loadingDates ? (
                  <p className="section-spacing">
                    {translations[language].loadingDates}
                  </p>
                ) : (
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dayClassName={dayClassName}
                    inline
                    locale="en-US"
                    openToDate={defaultDate}
                    filterDate={(date) =>
                      availableDates.includes(format(date, 'yyyy-MM-dd')) ||
                      userBookedDates.includes(format(date, 'yyyy-MM-dd'))
                    }
                  />
                )}
                {selectedDate && (
                  <div className="timeslots-container section-spacing">
                    {eventDetails && (
                      <div className="event-details">
                        <h3>
                          {language === 'zh'
                            ? `Week ${eventDetails.week_no}: ${eventDetails.event_name}`
                            : `Week ${eventDetails.week_no}: ${eventDetails.event_name}`}
                        </h3>
                        <p><strong>Date:</strong> {eventDetails.date}</p>
                        {/* <p><strong>Duration:</strong> {eventDetails.duration_info}</p> */}
                        <p>
                          <strong>{programs[eventDetails.program]?.name}</strong>
                        </p>
                        <p>{programs[eventDetails.program]?.intro}</p>
                        {eventDetails.location && eventDetails.location.length > 0 && (
                          <div>
                            <p>
                              <strong>Location:</strong>{' '}
                              {eventDetails.location.map((loc) => loc.location_name).join(', ')}
                            </p>
                          </div>
                        )}
                        {eventDetails.label && eventDetails.label.length > 0 && (
                          <div>
                            <p>
                              <strong>Label:</strong>{' '}
                              {eventDetails.label.map((label) => label.label_name).join(', ')}
                            </p>
                          </div>
                        )}
                        {eventDetails.partner && eventDetails.partner.length > 0 && (
                          <div>
                            <p>
                              <strong>Partner:</strong>{' '}
                              {eventDetails.partner.map((partner) => partner.name).join(', ')}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                    <h2>
                      {language === 'zh'
                        ? translations.zh.step3.timeslotHeader.replace(
                            '{date}',
                            format(selectedDate, 'yyyy-MM-dd')
                          )
                        : translations.en.step3.timeslotHeader.replace(
                            '{date}',
                            format(selectedDate, 'EEEE, MMMM do, yyyy')
                          )}
                    </h2>
                    {loadingTimeslots ? (
                      <p className="section-spacing">
                        {translations[language].loadingTimeslots}
                      </p>
                    ) : performances.length === 0 ? (
                      <p className="section-spacing">
                        {translations[language].noTimeslots}
                      </p>
                    ) : (
                      <div className="timeslots-grid section-spacing">
                        {performances.map((performance) => {
                          const isBooked = performance.dj.length > 0;
                          const isBookedByUser = performance.dj.some(
                            (dj) => dj.username === username
                          );
                          const isSelected =
                            selectedPerformance &&
                            selectedPerformance.id === performance.id;
                          return (
                            <button
                              key={performance.id}
                              className={`timeslot-button ${
                                isBooked
                                  ? isBookedByUser
                                    ? 'booked-by-user'
                                    : 'booked'
                                  : isSelected
                                  ? 'selected'
                                  : ''
                              }`}
                              onClick={() => handlePerformanceSelection(performance)}
                              disabled={isBooked && !isBookedByUser}
                            >
                              {performance.time.slice(0, 5)}
                              {isBookedByUser &&
                                ` - ${
                                  language === 'zh'
                                    ? '您已预约此时段'
                                    : 'You booked this slot'
                                }`}
                            </button>
                          );
                        })}
                      </div>
                    )}
                    <div className="navigation-buttons">
                      <button
                        className="previous-button"
                        onClick={handlePrevious}
                        disabled={isPreviousLoading}
                      >
                        {isPreviousLoading ? (
                          <span className="spinner"></span>
                        ) : (
                          translations[language].step3.previous
                        )}
                      </button>
                      <button
                        className="next-button"
                        onClick={handleNext}
                        disabled={isNextLoading || !selectedPerformance}
                      >
                        {isNextLoading ? (
                          <span className="spinner"></span>
                        ) : (
                          translations[language].step3.next
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* Step 4: Performance Type Selection */}
            {step === 4 && (
              <>
                <h4>{translations[language].step4.header}</h4>
                <div className="performance-type-selection">
                  <label>
                    <input
                      type="radio"
                      name="performanceType"
                      value="dj"
                      checked={isLive === false}
                      onChange={() => setIsLive(false)}
                    />
                    {translations[language].step4.djSet}
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="performanceType"
                      value="live"
                      checked={isLive === true}
                      onChange={() => setIsLive(true)}
                    />
                    {translations[language].step4.liveSet}
                  </label>
                </div>

                {/* If DJ Set is selected, show mixer model options */}
                {isLive === false && (
                  <div className="mixer-model-selection">
                    <label>
                      {translations[language].step4.mixerModelLabel}
                      <select
                        value={mixerModel}
                        onChange={(e) => setMixerModel(e.target.value)}
                      >
                        <option value="DJM-900 NXS2">DJM-900 NXS2</option>
                        <option value="XONE 96">XONE 96</option>
                      </select>
                    </label>
                  </div>
                )}

                <div className="navigation-buttons">
                  <button
                    className="previous-button"
                    onClick={handlePrevious}
                    disabled={isPreviousLoading}
                  >
                    {isPreviousLoading ? (
                      <span className="spinner"></span>
                    ) : (
                      translations[language].step4.previous
                    )}
                  </button>
                  <button
                    className="next-button"
                    onClick={handleNext}
                    disabled={isNextLoading || isLive === null}
                  >
                    {isNextLoading ? (
                      <span className="spinner"></span>
                    ) : (
                      translations[language].step4.next
                    )}
                  </button>
                </div>
              </>
            )}
            {/* Step 5: Confirm Booking */}
            {step === 5 && (
              <>
                <div className="booking-summary">
                <h4>{translations[language].step5.header}</h4>
                  <p>
                    <strong>{translations[language].step5.djName}:</strong>{' '}
                    {djProfile?.name || username} 
                  </p>
                  <p>
                    <strong>{translations[language].step5.date}:</strong>{' '}
                    {format(selectedDate, 'yyyy-MM-dd')}
                  </p>
                  {selectedPerformance ? (
                  <p>
                    <strong>{translations[language].step5.time}:</strong>{' '}
                    {selectedPerformance.time.slice(0, 5)}
                  </p>
                  ) : (
                    <p>No performance selected.</p>
                  )}
                  <p>
                    <strong>{translations[language].step5.performanceType}:</strong>{' '}
                    {isLive
                      ? translations[language].step5.liveSet
                      : translations[language].step5.djSet}
                  </p>
                  {!isLive && (
                    <p>
                      <strong>{translations[language].step5.mixerModel}:</strong> {mixerModel}
                    </p>
                  )}
                </div>
                <div className="navigation-buttons">
                  <button
                    className="previous-button"
                    onClick={handlePrevious}
                    disabled={isPreviousLoading}
                  >
                    {isPreviousLoading ? (
                      <span className="spinner"></span>
                    ) : (
                      translations[language].step5.previous
                    )}
                  </button>
                  <button
                    className="confirm-button"
                    onClick={handleBookPerformance}
                    disabled={isBooking}
                  >
                    {isBooking ? (
                      <span className="spinner"></span>
                    ) : (
                      translations[language].step5.confirm
                    )}
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>

      {/* Loading Overlay */}
      {isLoading && (
        <div className="loading-overlay">
          <img
            src="static/img/loading.png" // Path relative to React's public folder
            alt="Loading"
            className="loading-gif"
          />
        </div>
      )}
    </div>
  );
};

export default Booking;
