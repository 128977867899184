import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import './AccountSettings.css'; 
import { toast } from 'react-toastify';
import './loading-overlay.css';

const AccountSettings = () => {
  const { isAuthenticated, username: currentUsername } = useContext(AuthContext);

  const [initialUsername, setInitialUsername] = useState(currentUsername);
  const [username, setUsername] = useState(currentUsername);
  
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [showUsernameForm, setShowUsernameForm] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);

  if (!isAuthenticated) {
    return <div>Access Denied</div>;
  }

  const handleUsernameSubmit = async (e) => {
    e.preventDefault();

    if (username === initialUsername) {
      toast.info('No changes to username.');
      return;
    }

    try {
      const response = await axios.put('/api/dj/account_settings/', { username });
      toast.success(response.data.detail || 'Username updated successfully');
      setInitialUsername(username);
      setShowUsernameForm(false);
    } catch (err) {
      const errorMsg = err.response?.data?.username?.[0] || 'Error updating username.';
      toast.error(errorMsg);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    if (!oldPassword && !newPassword && !confirmNewPassword) {
      toast.info('No changes to password.');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      toast.error('New password and confirm password do not match.');
      return;
    }

    try {
      const response = await axios.put('/api/dj/account_settings/', {
        old_password: oldPassword,
        new_password: newPassword,
      });
      toast.success(response.data.detail || 'Password updated successfully');
      setOldPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      setShowPasswordForm(false);
    } catch (err) {
      const errorMsg =
        err.response?.data?.old_password?.[0] ||
        err.response?.data?.new_password?.[0] ||
        err.response?.data?.non_field_errors?.[0] ||
        'Error updating password.';
      toast.error(errorMsg);
    }
  };

  return (
    <div className="account-settings-container">
      <h2>Account Settings</h2>
      
      <p><strong>Current Username:</strong> {initialUsername}</p>

      {!showUsernameForm && !showPasswordForm && (
        <div className="account-actions">
          <button onClick={() => setShowUsernameForm(true)}>Change Username</button>
          <button onClick={() => setShowPasswordForm(true)}>Change Password</button>
        </div>
      )}

      {showUsernameForm && (
        <form onSubmit={handleUsernameSubmit}>
          <h3>Change Username</h3>
          <label>
            New Username:
            <input
              type="text"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </label>
          <div className="form-actions">
            <button type="submit">Update Username</button>
            <button type="button" onClick={() => {
              setUsername(initialUsername);
              setShowUsernameForm(false);
            }}>
              Cancel
            </button>
          </div>
        </form>
      )}

      {showPasswordForm && (
        <form onSubmit={handlePasswordSubmit}>
          <h3>Change Password</h3>
          <label>
            Old Password:
            <input
              type="password"
              name="old_password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
          </label>
          <label>
            New Password:
            <input
              type="password"
              name="new_password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              minLength={6}
            />
          </label>
          <label>
            Confirm New Password:
            <input
              type="password"
              name="confirm_new_password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              required
            />
          </label>
          <div className="form-actions">
            <button type="submit">Update Password</button>
            <button type="button" onClick={() => {
              setOldPassword('');
              setNewPassword('');
              setConfirmNewPassword('');
              setShowPasswordForm(false);
            }}>
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default AccountSettings;
