import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [userRoles, setUserRoles] = useState([]); // Now we store roles in an array
    const [username, setUsername] = useState('');
    const [hasDjProfile, setHasDjProfile] = useState(false);
    const [loading, setLoading] = useState(true);

    axios.defaults.withCredentials = true;

    const getCsrfToken = async () => {
        try {
            await axios.get('/api/get_csrf_token/');
        } catch (err) {
            console.error('Failed to fetch CSRF token:', err);
        }
    };

    const fetchAuthStatus = async () => {
        try {
            const response = await axios.get('/api/auth/status/');
            setIsAuthenticated(response.data.isAuthenticated);
            setUserRoles(response.data.roles || []);
            setUsername(response.data.username || '');
            setHasDjProfile(response.data.hasDjProfile || false);
        } catch (err) {
            setIsAuthenticated(false);
            setUserRoles([]);
            setUsername('');
            setHasDjProfile(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const initializeAuth = async () => {
            await getCsrfToken();
            await fetchAuthStatus();
        };
        initializeAuth();
    }, []);

    const login = async (credentials) => {
        try {
            await axios.post('/api/login/', credentials);
            await fetchAuthStatus();
            return { success: true };
        } catch (err) {
            const errorMessage =
                err.response?.data?.error ||
                err.response?.data?.detail ||
                'Invalid credentials';
            return { success: false, message: errorMessage };
        }
    };

    const logout = async () => {
        try {
            await axios.post('/api/logout/');
            setIsAuthenticated(false);
            setUserRoles([]);
            setUsername('');
            setHasDjProfile(false);
        } catch (err) {
            console.error('Error logging out:', err);
        }
    };

    const signup = async (userData) => {
        try {
            await axios.post('/api/signup/', userData);
            return { success: true };
        } catch (err) {
            const errorMessage =
                err.response?.data?.error ||
                err.response?.data?.detail ||
                'Signup failed';
            return { success: false, message: errorMessage };
        }
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                userRoles,
                username,
                hasDjProfile,
                loading,
                login,
                logout,
                signup,
                fetchAuthStatus,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
