import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const MaterialAdminList = () => {
  const [performances, setPerformances] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchPerformances = (page) => {
    axios.get(`/api/material/performances/?page=${page}`)
      .then(res => {
        setPerformances(res.data.results);
        setTotalPages(Math.ceil(res.data.count / 10));
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    fetchPerformances(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>Material Admin - Performances</h2>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th>Performance ID</th>
            <th>Title</th>
            <th>Event Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {performances.map(perf => (
            <tr key={perf.id} style={{ borderBottom: '1px solid #ccc' }}>
              <td>{perf.id}</td>
              <td>{perf.title}</td>
              <td>{perf.event_name}</td>
              <td>
                <Link to={`/material_admin/${perf.id}`}>Edit Materials</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={{ marginTop: '20px' }}>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNum => (
          <button
            key={pageNum}
            onClick={() => handlePageChange(pageNum)}
            disabled={pageNum === currentPage}
            style={{ margin: '0 5px' }}
          >
            {pageNum}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MaterialAdminList;
