import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from './AuthContext';
import axios from 'axios';
import './UserAdmin.css';
import { toast } from 'react-toastify';

const ALL_ROLES = ['dj', 'content_manager', 'operation_admin', 'user_admin', 'superuser'];

const UserAdmin = () => {
  const { isAuthenticated, userRoles } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  // Assuming a user admin can only be user_admin or superuser
  const isSuperuser = userRoles.includes('superuser');
  const isUserAdmin = userRoles.includes('user_admin') || isSuperuser;

  useEffect(() => {
    const fetchUsers = async () => {
      if (isAuthenticated && isUserAdmin) {
        try {
          const response = await axios.get('/api/users/', { withCredentials: true });
          setUsers(response.data); // {id, username, roles: []}
        } catch (error) {
          console.error('Error fetching users:', error);
          toast.error('Failed to fetch user list.');
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    fetchUsers();
  }, [isAuthenticated, isUserAdmin]);

  if (!isAuthenticated || !isUserAdmin) {
    return <div>You do not have permission to access this page.</div>;
  }

  if (loading) {
    return <div>Loading users...</div>;
  }

  if (users.length === 0) {
    return <div>No users found.</div>;
  }

  const handleRoleChange = async (userId, role, isChecked) => {
    // If role is dj and user tries to uncheck it (revoke), do nothing
    if (role === 'dj' && !isChecked) {
      // User admin cannot revoke dj role.
      return;
    }

    let endpoint;
    if (role === 'dj') {
      endpoint = isChecked ? '/api/users/grant_dj_role/' : '/api/users/revoke_dj_role/';
    } else if (role === 'operation_admin') {
      endpoint = isChecked ? '/api/users/grant_operation_admin_role/' : '/api/users/revoke_operation_admin_role/';
    } else if (role === 'user_admin') {
      endpoint = isChecked ? '/api/users/grant_user_admin_role/' : '/api/users/revoke_user_admin_role/';
    } else if (role === 'content_manager') {
      endpoint = isChecked ? '/api/users/grant_content_manager_role/' : '/api/users/revoke_content_manager_role/';
    } else if (role === 'superuser') {
      // superuser role changes not handled here
      toast.error('Superuser role changes are not supported.');
      return;
    }

    try {
      await axios.post(endpoint, { user_id: userId }, { withCredentials: true });
      toast.success(`${isChecked ? 'Granted' : 'Revoked'} ${role} role successfully.`);
      // Refresh user list to reflect changes
      refreshUserList();
    } catch (err) {
      console.error(`Error changing ${role} role:`, err);
      toast.error(`Error changing ${role} role.`);
    }
  };

  const refreshUserList = async () => {
    try {
      const response = await axios.get('/api/users/', { withCredentials: true });
      setUsers(response.data);
    } catch (error) {
      console.error('Error refreshing user list:', error);
    }
  };

  return (
    <div className="user-admin-container">
      <h2>User Management</h2>
      <p>Manage roles of each user by checking or unchecking the boxes below:</p>
      <table className="user-table">
        <thead>
          <tr>
            <th>Username</th>
            {ALL_ROLES.map(role => (
              <th key={role}>{role}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {users.map(user => {
            const userRoleSet = new Set(user.roles);
            return (
              <tr key={user.id}>
                <td>{user.username}</td>
                {ALL_ROLES.map(role => {
                  const isChecked = userRoleSet.has(role);

                  // For superuser: read-only
                  const isSuperuserField = (role === 'superuser');

                  // DJ role: If user already has DJ role, disable checkbox to prevent revoking
                  // If user doesn't have DJ, we can allow checking to grant DJ.
                  let disabled = false;
                  if (role === 'dj' && isChecked) {
                    // Already has dj role, cannot revoke, so disable
                    disabled = true;
                  }

                  // If superuser field, disable changes
                  if (isSuperuserField) {
                    disabled = true;
                  }

                  return (
                    <td key={role}>
                      <input
                        type="checkbox"
                        checked={isChecked}
                        disabled={disabled}
                        onChange={(e) => handleRoleChange(user.id, role, e.target.checked)}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default UserAdmin;
