import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const MaterialAdminDetail = () => {
  const { performanceId } = useParams();
  const [performanceData, setPerformanceData] = useState(null);
  const [selectedThumbnailId, setSelectedThumbnailId] = useState(null);
  const [selectedSnippetIds, setSelectedSnippetIds] = useState([]);
  const [currentSnippet, setCurrentSnippet] = useState(null);
  const [trimStart, setTrimStart] = useState(0);
  const [trimEnd, setTrimEnd] = useState(120);
  const [brightness, setBrightness] = useState(1.0);
  const [contrast, setContrast] = useState(1.0);
  const [saturation, setSaturation] = useState(1.0);

  useEffect(() => {
    axios.get(`/api/material/performance/${performanceId}/`)
      .then(res => {
        setPerformanceData(res.data);
        const bestThumb = res.data.thumbnails.find(t => t.is_best);
        if (bestThumb) setSelectedThumbnailId(bestThumb.id);
        const selectedSnippets = res.data.snippets.filter(s => s.is_selected).map(s => s.id);
        setSelectedSnippetIds(selectedSnippets);
      })
      .catch(err => console.error(err));
  }, [performanceId]);

  const handleSelectThumbnail = (thumbId) => {
    setSelectedThumbnailId(thumbId);
  };

  const handleToggleSnippetSelection = (snippetId) => {
    setSelectedSnippetIds(prev => {
      if (prev.includes(snippetId)) {
        return prev.filter(id => id !== snippetId);
      } else {
        return [...prev, snippetId];
      }
    });
  };

  const handleSnippetEdit = (snippet) => {
    setCurrentSnippet(snippet);
    setTrimStart(snippet.start_time);
    setTrimEnd(snippet.end_time);
    setBrightness(snippet.brightness || 1.0);
    setContrast(snippet.contrast || 1.0);
    setSaturation(snippet.saturation || 1.0);
  };

  const handleSaveChanges = () => {
    axios.post(`/api/material/performance/${performanceId}/`, {
      best_thumbnail_id: selectedThumbnailId,
      selected_snippet_ids: selectedSnippetIds
    }).then(() => {
      alert("Selections saved");
    }).catch(err => console.error(err));
  };

  const handleTrimSnippet = () => {
    if (!currentSnippet) return;
    axios.post(`/api/material/snippet/${currentSnippet.id}/trim/`, {
      start_time: trimStart,
      end_time: trimEnd
    }).then(res => {
      alert("Snippet trimmed");
      setPerformanceData(prev => {
        const newSnippets = prev.snippets.map(s => s.id === currentSnippet.id ? res.data : s);
        return { ...prev, snippets: newSnippets };
      });
    }).catch(err => console.error(err));
  };

  const handleAdjustSnippet = () => {
    if (!currentSnippet) return;
    axios.post(`/api/material/snippet/${currentSnippet.id}/adjust/`, {
      brightness,
      contrast,
      saturation
    }).then(() => {
      alert("Adjustments saved");
      setPerformanceData(prev => {
        const newSnippets = prev.snippets.map(s => {
          if (s.id === currentSnippet.id) {
            return { ...s, brightness, contrast, saturation };
          }
          return s;
        });
        return { ...prev, snippets: newSnippets };
      });
    }).catch(err => console.error(err));
  };

  if (!performanceData) return <div>Loading...</div>;

  return (
    <div style={{ padding: '20px' }}>
      <h2>Material Admin - Editing Performance {performanceId}</h2>

      <h3>Thumbnails</h3>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {performanceData.thumbnails.map(thumb => (
          <div key={thumb.id} style={{ margin: '10px', border: thumb.id === selectedThumbnailId ? '3px solid blue' : '1px solid gray' }}>
            <img src={thumb.image_url} alt="thumbnail" style={{ width: '200px', height: 'auto' }} onClick={() => handleSelectThumbnail(thumb.id)}/>
            <p>Time: {thumb.timecode}s</p>
          </div>
        ))}
      </div>

      <h3>Highlight Snippets</h3>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {performanceData.snippets.map(sn => (
          <div key={sn.id} style={{ margin: '10px', border: selectedSnippetIds.includes(sn.id) ? '3px solid green' : '1px solid gray' }}>
            <video 
              src={sn.snippet_url} 
              width="200" 
              height="112"
              style={{ filter: `brightness(${sn.brightness}) contrast(${sn.contrast}) saturate(${sn.saturation})` }}
              controls
            ></video>
            <p>{sn.start_time}s - {sn.end_time}s</p>
            <button onClick={() => handleToggleSnippetSelection(sn.id)}>
              {selectedSnippetIds.includes(sn.id) ? 'Deselect' : 'Select'}
            </button>
            <button onClick={() => handleSnippetEdit(sn)}>Edit</button>
          </div>
        ))}
      </div>

      {currentSnippet && (
        <div style={{ marginTop: '20px' }}>
          <h4>Edit Snippet {currentSnippet.id}</h4>
          <div>
            <label>Trim Start (s): </label>
            <input type="number" value={trimStart} onChange={e => setTrimStart(parseInt(e.target.value))}/>
            <label> Trim End (s): </label>
            <input type="number" value={trimEnd} onChange={e => setTrimEnd(parseInt(e.target.value))}/>
            <button onClick={handleTrimSnippet}>Trim</button>
          </div>
          <div>
            <label>Brightness: </label>
            <input type="range" min="0.5" max="2" step="0.1" value={brightness} onChange={e => setBrightness(parseFloat(e.target.value))}/>
            <label>Contrast: </label>
            <input type="range" min="0.5" max="2" step="0.1" value={contrast} onChange={e => setContrast(parseFloat(e.target.value))}/>
            <label>Saturation: </label>
            <input type="range" min="0.5" max="2" step="0.1" value={saturation} onChange={e => setSaturation(parseFloat(e.target.value))}/>
            <button onClick={handleAdjustSnippet}>Adjust</button>
          </div>
        </div>
      )}

      <button onClick={handleSaveChanges} style={{ marginTop: '20px', padding: '10px', background: 'blue', color: 'white' }}>
        Save Selected Materials
      </button>
    </div>
  );
};

export default MaterialAdminDetail;
