import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ContentManage.css';
import './loading-overlay.css';

const ContentManage = () => {
    const [events, setEvents] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);
    const [loadingSets, setLoadingSets] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        fetchEvents(currentPage);
    }, [currentPage]);

    const fetchEvents = async (page = 1) => {
        setLoadingSets(true);
        try {
            const response = await axios.get('/api/content_manager/events/', {
                params: { page }
            });
            // DRF pagination returns results in `response.data.results`
            setEvents(response.data.results);
            setHasNextPage(!!response.data.next);
            setHasPreviousPage(!!response.data.previous);
        } catch (error) {
            console.error('Error fetching events:', error);
        } finally {
            setLoadingSets(false);
        }
    };

    const handleEventClick = (eventId) => {
        navigate(`/content_manager/events/${eventId}`);
    };

    function isDarkColor(hexColor) {
        if (!hexColor) return false;
        const hex = hexColor.replace('#', '');
        const r = parseInt(hex.substr(0, 2), 16);
        const g = parseInt(hex.substr(2, 2), 16);
        const b = parseInt(hex.substr(4, 2), 16);
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        return brightness < 128; // lower brightness = darker color
    }

    const handleNextPage = () => {
        if (hasNextPage) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (hasPreviousPage) {
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
        }
    };

    const filteredEvents = events.filter(event => {
        const eventDate = new Date(event.date);
        const today = new Date();
        // Compare dates (we only show events whose date is strictly before today)
        return eventDate < today;
    });

    return (
        <div className="content-manage-container">
            {loadingSets && (
                <div className="loading-overlay">
                    <img src="static/img/loading.png" alt="Loading" className="loading-gif" />
                </div>
            )}
            <h1>Content Manager Dashboard</h1>



            <div className="events-grid">
                {filteredEvents.map((event) => {
                    const darkBackground = isDarkColor(event.color);
                    const textColor = darkBackground ? '#fff' : '#000';
                    return (
                        <div
                            key={event.id}
                            className="event-box"
                            style={{ backgroundColor: event.color, color: textColor }}
                            onClick={() => handleEventClick(event.id)}
                        >
                            <div className="event-info">
                                <p><strong>Date:</strong> {event.date}</p>
                                <p><strong>Week No:</strong> {event.week_no}</p>
                                <p><strong>Event Name:</strong> {event.event_name}</p>
                                <p><strong>Location:</strong> {event.location.map(loc => loc.location_name).join(', ')}</p>
                                {((event.label && event.label.length > 0) || (event.partner && event.partner.length > 0)) && (
                                    <div className="labels-partners">
                                        {event.label && event.label.length > 0 && (
                                            <div className="labels">
                                                {event.label.map(label => (
                                                    <img
                                                        key={label.id}
                                                        src={label.label_logo_1 || label.label_logo_2}
                                                        alt={label.label_name}
                                                        className="logo-image"
                                                    />
                                                ))}
                                            </div>
                                        )}
                                        {event.partner && event.partner.length > 0 && (
                                            <div className="partners">
                                                {event.partner.map(partner => (
                                                    <img
                                                        key={partner.id}
                                                        src={partner.logo}
                                                        alt={partner.name}
                                                        className="logo-image"
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="event-lineup">
                                {event.performances && event.performances.length > 0 && (
                                    <>
                                        <p><strong>Lineup:</strong></p>
                                        {event.performances.map(performance => (
                                            <div key={performance.id} className="artist-row">
                                                <span>{performance.time} - {performance.dj.map(dj => dj.name).join(', ')}</span>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>



            <div className="pagination-controls" style={{ marginTop: '20px' }}>
                <button onClick={handlePreviousPage} disabled={!hasPreviousPage}>Previous</button>
                <span>Page {currentPage}</span>
                <button onClick={handleNextPage} disabled={!hasNextPage}>Next</button>
            </div>
        </div>
    );
};

export default ContentManage;