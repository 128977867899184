import React, { useState, useEffect, useContext } from 'react';
import axios from './axiosConfig'; // Ensure Axios is centralized
import './Apply.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { AuthContext } from './AuthContext';

const Apply = () => {
    const navigate = useNavigate(); // Initialize useNavigate
    const { isAuthenticated, hasApplication, applicationApproved } = useContext(AuthContext);

    const [formData, setFormData] = useState({
        name: '',
        instagram: '',
        wechat: '',
        intro: '',
        soundcloud: '',
        previous_work: '',
        profile_pic: null,
        program: '',
        collective_name: '',
        collective_description: '',
        collective_logo: null,
        partner_name: '',
        partner_description: '',
        partner_logo: null,
    });

    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false); // Changed from string to boolean

    // Define program explanations
    const programExplanations = {
        rs: {
            en: "Main Program of byyb.radio, held every Weekend",
            zh: "电台每周活动: 个人展示，表演一波",
        },
        cs: {
            en: "If you want to take over the radio as a label/collective",
            zh: "厂牌/团体 Take Over: 团队展示，表演一波",
        },
        ia: {
            en: "Ivy Arum Session: Non-club music only",
            zh: "绿萝时间: 听点俱乐部里听不到的音乐",
        },
        cc: {
            en: "Other Inquiries: Art / Brand / Venue / Promoter / Events Collaboration or Service Inquiries",
            zh: "其他合作项目：艺术/品牌/场地/主办/活动以及服务咨询",
        },
    };

    /**
     * Place all Hooks at the top level, before any early returns.
     * This ensures Hooks are called in the same order on every render.
     */

    // Redirect unauthenticated users to signup or login
    useEffect(() => {
        if (isAuthenticated === false) {
            navigate('/signup');
        }
    }, [isAuthenticated, navigate]);

    // Handle redirect after successful submission
    useEffect(() => {
        if (success) {
            // Redirect to index after 5 seconds
            const timer = setTimeout(() => {
                navigate('/'); // Redirect to homepage
            }, 5000);

            // Cleanup the timer if the component unmounts before the timeout
            return () => clearTimeout(timer);
        }
    }, [success, navigate]);

    // **Now place any early returns after all Hooks**

    // If user already has an application, show appropriate message
    if (hasApplication) {
        if (applicationApproved) {
            return <div>Your application has been approved!</div>;
        } else {
            return <div>Your application is pending approval.</div>;
        }
    }

    // Handle input changes
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            setFormData({
                ...formData,
                [name]: files[0],
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    // Handle program selection changes
    const handleProgramChange = (e) => {
        const selectedProgram = e.target.value;
        setFormData({
            ...formData,
            program: selectedProgram,
            // Reset conditional fields when program changes
            collective_name: '',
            collective_description: '',
            collective_logo: null,
            partner_name: '',
            partner_description: '',
            partner_logo: null,
        });
    };

    // Validate form fields
    const validate = () => {
        let tempErrors = {};
        if (!formData.name.trim()) tempErrors.name = 'Name is required.';
        if (!formData.program) tempErrors.program = 'Program selection is required.';

        if (formData.program === 'cs') { // Collective Showcase
            if (!formData.collective_name.trim()) tempErrors.collective_name = 'Collective name is required.';
            if (!formData.collective_description.trim()) tempErrors.collective_description = 'Description is required.';
        }

        if (formData.program === 'cc') { // Creative Collaboration
            if (!formData.partner_name.trim()) tempErrors.partner_name = 'Partner name is required.';
            if (!formData.partner_description.trim()) tempErrors.partner_description = 'Description is required.';
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default GET request

        if (!validate()) return; // Stop submission if validation fails

        const data = new FormData();
        data.append('name', formData.name);
        data.append('instagram', formData.instagram);
        data.append('wechat', formData.wechat);
        data.append('intro', formData.intro);
        data.append('soundcloud', formData.soundcloud);
        data.append('previous_work', formData.previous_work);
        if (formData.profile_pic) data.append('profile_pic', formData.profile_pic);
        data.append('program', formData.program);

        if (formData.program === 'cs') { // Collective Showcase
            data.append('collective_name', formData.collective_name);
            data.append('collective_description', formData.collective_description);
            if (formData.collective_logo) data.append('collective_logo', formData.collective_logo);
        }

        if (formData.program === 'cc') { // Creative Collaboration
            data.append('partner_name', formData.partner_name);
            data.append('partner_description', formData.partner_description);
            if (formData.partner_logo) data.append('partner_logo', formData.partner_logo);
        }

        try {
            await axios.post('/apply/', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setSuccess(true); // Set success to true
            setErrors({});
            // Reset the form
            setFormData({
                name: '',
                instagram: '',
                wechat: '',
                intro: '',
                soundcloud: '',
                previous_work: '',
                profile_pic: null,
                program: '',
                collective_name: '',
                collective_description: '',
                collective_logo: null,
                partner_name: '',
                partner_description: '',
                partner_logo: null,
            });
        } catch (error) {
            console.error('Error submitting application:', error);
            setSuccess(false);
            if (error.response && error.response.data) {
                // Handle validation errors from the backend
                setErrors(error.response.data);
            } else {
                setErrors({ submit: 'An error occurred. Please try again.' });
            }
        }
    };

    // **Render the component UI**

    return (
        <div className="apply-container">
            {success ? (
                <div className="success-message">
                    <p>Your application has been submitted successfully! 您的申请已成功提交！</p>
                    <p>Redirecting to the homepage in 5 seconds... 5秒后将重定向到主页...</p>
                </div>
            ) : (
                <>
                    <h2>Radio Application | 电台申请做客 🪴</h2>
                    <form onSubmit={handleSubmit}>
                    <div className="form-group">
                            <label htmlFor="name">Name 称呼 *</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                placeholder="e.g., John Doe 例如，约翰·多伊"
                            />
                            {errors.name && <span className="error">{errors.name}</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="program">Program Applying To 申请项目 *</label>
                            <select
                                id="program"
                                name="program"
                                value={formData.program}
                                onChange={handleProgramChange}
                                required
                            >
                                <option value="">Select a program 请选择一个项目</option>
                                <option value="rs">💃 Individual Showcase 个人展示</option>
                                <option value="cs">🚌 Collective Showcase 集体展示</option>
                                <option value="ia">🪴 Ivy Arum Session 绿萝时间</option>
                                <option value="cc">🤝 Creative Collaboration 创意合作</option>
                            </select>
                            {errors.program && <span className="error">{errors.program}</span>}
                        </div>

                        {/* Display program explanation if a program is selected */}
                        {formData.program && (
                            <div className="program-explanation">
                                <p><strong>English:</strong> {programExplanations[formData.program].en}</p>
                                <p><strong>中文:</strong> {programExplanations[formData.program].zh}</p>
                            </div>
                        )}

                        {/* Conditional Form Fields Based on Program Selection */}
                        {formData.program === 'cs' && ( // Collective Showcase
                            <>
                                <div className="form-group">
                                    <label htmlFor="collective_name">Collective Name 厂牌/团体名称 *</label>
                                    <input
                                        type="text"
                                        id="collective_name"
                                        name="collective_name"
                                        value={formData.collective_name}
                                        onChange={handleChange}
                                        required
                                        placeholder="e.g., Collective XYZ 例如，集体XYZ"
                                    />
                                    {errors.collective_name && <span className="error">{errors.collective_name}</span>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="collective_description">Collective Description 厂牌/团体简介 *</label>
                                    <textarea
                                        id="collective_description"
                                        name="collective_description"
                                        value={formData.collective_description}
                                        onChange={handleChange}
                                        required
                                        placeholder="Describe your collective... 描述您的集体..."
                                    ></textarea>
                                    {errors.collective_description && <span className="error">{errors.collective_description}</span>}
                                </div>
                            </>
                        )}

                        {formData.program === 'cc' && ( // Creative Collaboration
                            <>
                                <div className="form-group">
                                    <label htmlFor="partner_name">Partner Name 合作方名称 *</label>
                                    <input
                                        type="text"
                                        id="partner_name"
                                        name="partner_name"
                                        value={formData.partner_name}
                                        onChange={handleChange}
                                        required
                                        placeholder="e.g., 请问你们怎么称呼？"
                                    />
                                    {errors.partner_name && <span className="error">{errors.partner_name}</span>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="partner_description">Partner Description 合作描述 *</label>
                                    <textarea
                                        id="partner_description"
                                        name="partner_description"
                                        value={formData.partner_description}
                                        onChange={handleChange}
                                        required
                                        placeholder="Describe your project to collaborate, or some references or other inquiries that we can provide 是否有简单的合作构想，或者一些其他需求？"
                                    ></textarea>
                                    {errors.partner_description && <span className="error">{errors.partner_description}</span>}
                                </div>
                            </>
                        )}


                        <div className="form-group">
                            <label htmlFor="instagram">Instagram</label>
                            <input
                                type="text" 
                                id="instagram"
                                name="instagram"
                                value={formData.instagram}
                                onChange={handleChange}
                                placeholder="e.g., byyb.radio"
                            />
                            {errors.instagram && <span className="error">{errors.instagram}</span>}
                        </div>

                        <div className="form-group">
                            <label>WeChat ID 微信号</label>
                            <input
                                type="text"
                                name="wechat"
                                value={formData.wechat}
                                onChange={handleChange}
                                placeholder="Your WeChat ID 你的微信号"
                            />
                            {errors.wechat && <span className="error">{errors.wechat}</span>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="intro">Introduction 介绍</label>
                            <textarea
                                id="intro"
                                name="intro"
                                value={formData.intro}
                                onChange={handleChange}
                                placeholder="Tell us about yourself... 告诉我们关于您的信息..."
                            ></textarea>
                            {errors.intro && <span className="error">{errors.intro}</span>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="soundcloud">SoundCloud</label>
                            <input
                                type="text"  // Changed from "url" to "text"
                                id="soundcloud"
                                name="soundcloud"
                                value={formData.soundcloud}
                                onChange={handleChange}
                                placeholder="e.g., byyb.radio or soundcloud.com/byyb.radio"
                            />
                            {errors.soundcloud && <span className="error">{errors.soundcloud}</span>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="previous_work">Previous Work 以前的作品</label>
                            <textarea
                                id="previous_work"
                                name="previous_work"
                                value={formData.previous_work}
                                onChange={handleChange}
                                placeholder="links to your previous mixes/work... 你的以前演出混音、创作作品"
                            ></textarea>
                            {errors.previous_work && <span className="error">{errors.previous_work}</span>}
                        </div>

                        {/* Mix Upload Section */}
                        {/* <div className="form-group">
                            <label>Mix Uploads 混音上传 (Optional)</label>
                            {mixes.map((mix) => (
                                <div key={mix.id} className="mix-upload">
                                    <input
                                        type="file"
                                        accept=".mp3"
                                        onChange={(e) => handleMixChange(mix.id, e.target.files[0])}
                                        placeholder="Select MP3 file 选择MP3文件"
                                    />
                                    {mix.error && <span className="error">{mix.error}</span>}
                                    {mixes.length > 1 && (
                                        <button type="button" onClick={() => removeMixField(mix.id)} className="remove-mix-btn">
                                            -
                                        </button>
                                    )}
                                </div>
                            ))}
                            <button type="button" onClick={addMixField} className="add-mix-btn">
                                + Add Mix
                            </button>
                        </div> */}

                        {errors.submit && <div className="error submit-error">{errors.submit}</div>}

                        <button type="submit">Submit Application 提交申请</button>
                    </form>
                </>
            )}
        </div>
    );
};

export default Apply;
