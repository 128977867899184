import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { toast } from 'react-toastify';

const ProtectedRoute = ({ children, roles = [], requireDjProfile = false }) => {
    const { isAuthenticated, userRoles, hasDjProfile, loading } = useContext(AuthContext);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    // If roles required, check if userRoles intersects with roles
    if (roles.length > 0 && !roles.some(r => userRoles.includes(r))) {
        toast.error('You do not have permission to access this page.');
        return <Navigate to="/" replace />;
    }

    if (requireDjProfile && !hasDjProfile) {
        toast.info('Please fill in your artist info first.');
        return <Navigate to="/dj/profile/" replace />;
    }

    return children;
};

export default ProtectedRoute;
